import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AuthenticationContext from "../../Authentication/types/AuthContextType";
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Error from '../Error';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import ParameterToolTipV1 from "./ParameterToolTipV1";

class CustomNumberSlider extends Component {
    static contextType = AuthenticationContext;

    constructor(props) {
        super(props);
        this.state = { dataLoaded: false, customValue: "0"};
        this.marks = null;
        //this.valuetext = this.valuetext.bind(this);
    }

    componentDidMount() {
        this.value = null;
    }

    //valuetext(value) {
    //    let finded = this.props.paramObj.values.find(elem => elem.value === value.toString());
    //    if (finded != undefined) {
    //        finded = finded.label;
    //        return `${finded}`;
    //    }
    //    return `${0}`;
    //}

    onMouseOverItem = e => {

        let menuPosition = document.getElementsByClassName("MuiAppBar-root")[0].getBoundingClientRect().top;
        let sliderPosition = document.getElementById(e.currentTarget.id).getBoundingClientRect().top

        let offset = sliderPosition - menuPosition;

        let element = document.getElementById("tooltip-" + e.currentTarget.id.replace('customNumberSlider-', ''));

        if (offset < 325) {
            if (element != undefined) {
                element.classList.remove('box');
                element.classList.add('BottomTooltip');
            }
        }
        else {
            if (element != undefined) {
                element.classList.remove('BottomTooltip');
                element.classList.add('box');
            }
        }
    }

    render() {
        let dataLoaded = false;
        let parameterValue = this.props.parameterValue;
        if (this.value != null) {
            parameterValue = this.value;
        }
        if (this.props.paramObj != null) {
            dataLoaded = true;
            //this.setState();
        }
        const { t } = this.props;
     
        const handleChange = (evnt, newValue) => {
            this.value = newValue;
            //const finded = this.props.paramObj.values.find(elem => elem.value === newValue.toString());
            //if (finded != undefined) {
            //    let lbl = document.getElementById('label-' + this.props.paramObj.businessLabel);
            //    lbl.innerHTML = finded.label;
            //}
        };
        
        const handleChangeCommited = (evnt, newValue) => {
            this.value = newValue;
            //const finded = this.props.paramObj.values.find(elem => elem.value === newValue.toString());
            //if (finded != undefined) {
            //    let lbl = document.getElementById('label-' + this.props.paramObj.businessLabel);
            //    lbl.innerHTML = finded.label;
            //    this.props.toSend(this.props.paramObj, newValue);
            //}
            this.props.toSend(this.props.paramObj, newValue);
            //Call parent onchange
            if (typeof this.props.onChange === 'function') {
                this.props.onChange('slider');
            }
        };

        const handleDisabledClick = (disabled)=>{
            if(this.props.handleDisabledMessage && disabled){
                this.props.handleDisabledMessage(disabled);
            }
        }


       
        if (dataLoaded) {

            let disabled = this.props.paramObj.permissionType == 'R' || !this.props.paramObj.online;
            const { t } = this.props;
            try {
                let style = {}
                if (this.props.propStyle)
                    style = this.props.propStyle

                let min = 0;
                let max = 0;

                if (this.props.paramObj.maxValue != undefined) {
                    max = parseInt(this.props.paramObj.maxValue);
                }
                if (this.props.paramObj.minValue != undefined) {
                    min = parseInt(this.props.paramObj.minValue);
                }

                parameterValue = parameterValue == null ? min : parameterValue;

                let minLabel = min;
                let maxLabel = max;
                if (this.props.paramObj.visibleUnits != undefined) {
                    minLabel = min + " " + t("parametersPage:visibleUnits." + this.props.paramObj.visibleUnits);
                    maxLabel = max + " " + t("parametersPage:visibleUnits." + this.props.paramObj.visibleUnits);
                }
                else if (this.props.paramObj.deviceUnits != undefined) {
                    minLabel = min + " " + t("parametersPage:deviceUnits." + this.props.paramObj.deviceUnits);
                    maxLabel = max + " " + t("parametersPage:deviceUnits." + this.props.paramObj.deviceUnits);
                }

                let name = t(this.props.paramObj.table + ':' + this.props.paramObj.smartComponentTypeId + '.' + this.props.paramObj.businessLabel + '.name');
                let description = t(this.props.paramObj.table + ':' + this.props.paramObj.smartComponentTypeId + '.' + this.props.paramObj.businessLabel + '.description');

                return (
                    <FormControl id={'customNumberSlider-' + this.props.paramObj.businessLabel} className="customSlider" variant="standard" onMouseOver={this.onMouseOverItem} >
                        <p>
                        <ParameterToolTipV1 values={this.props.telemetryObject} description={description}>
                            <Typography className="t2" id={'discrete-slider-restrict-' + this.props.paramObj.businessLabel} gutterBottom style={{marginBottom: '2em', fontWeight: "bold !important"}}>
                                {name}
                            </Typography>
                        </ParameterToolTipV1>
                        <FormLabel className="custom-slider-label t1 d-none" id={'label-' + this.props.paramObj.businessLabel}>{description}</FormLabel>
                        </p>
                        {this.props.paramObj.infoText ?
                            <div className='info-tooltip-parameter'>
                                <Tooltip title={this.props.paramObj.infoText}>
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        :""}
                        <div onClick={() => handleDisabledClick({readOnly: this.props.paramObj.permissionType == 'R', online: this.props.paramObj.online})}>
                            <Slider
                                id={'s' + this.props.paramObj.businessLabel}
                                name={'s' + this.props.paramObj.businessLabel}
                                min={min}
                                max={max}
                                defaultValue={parameterValue}
                                aria-labelledby="discrete-slider-restrict"
                                onChange={handleChange}
                                onChangeCommitted={handleChangeCommited}
                                step={1}
                                disabled={disabled}
                                valueLabelDisplay="on"
                            />
                        </div>
                        <div className="custom-slider-label-group mb-4" style={{ display: "flex", justifyContent: "space-between"}}>
                            <span className={'label-min t3'}>{minLabel}</span>
                            <span className={'label-max t3'}>{maxLabel}</span>
                        </div>
                    </FormControl>
                );
            } catch (e) {
                return (<Error errors={e} />);
            }

        }
        else {
            return (<div className="row loadingParent">< div className="loadingImg"></div></div >);
        }
    }
}
export default withTranslation()(CustomNumberSlider)