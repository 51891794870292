import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AuthenticationContext from '../../Authentication/types/AuthContextType';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Error from '../Error';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

function SetTheme() {
  const theme = useTheme();
  return theme;
}

class CustomTabSelect extends Component {
  static contextType = AuthenticationContext;

  constructor(props) {
    super(props);
    this.state = { indexTab: 0, tabValue: 0, values: [], dataLoaded: false, value: this.props.parameterValue };
    this.handleChange = this.handleChange.bind(this);
    this.handleDisabledClick = this.handleDisabledClick.bind(this);
  }

  validateTraduction = (table, key) => {
    const translatedText = this.props.t(table + ':' + key);
    return translatedText != key ? translatedText : false;
  };

  componentDidMount() {
    const { t } = this.props;
    let values = [];

    let type = this.props.type.toLowerCase();

    let tabValue = this.props.parameterValue;
    if (type == 'checkbox') {
      if (this.props.valuesIds != undefined) {
        values = this.props.valuesIds;
      } else {
        values = [
          { name: 'OFF', value: 0 },
          { name: 'ON', value: 1 },
        ];
      }
    } else if (type == 'dropdown') {
      for (let value of this.props.paramObj.values) {
        let label = t(this.props.paramObj.table + 'Labels:' + this.props.paramObj.id + '.' + value.value + '.name');
        let infoText = this.validateTraduction(
          this.props.paramObj.table + 'Labels',
          this.props.paramObj.id + '.' + value.value + '.infoText'
        );
        values.push({ name: label, value: parseInt(value.value), infoText: infoText });
      }
    }
    this.setState({ indexTab: this.getIndexTabWithParamValue(values), tabValue, values, value: this.props.parameterValue });
  }

  getIndexTabWithParamValue = (allParamValues) => {
    if(!this.props.parameterValue || (!allParamValues.length)){
      return 0
    }
    return allParamValues.findIndex(param => param.value === this.props.parameterValue);
  }

  handleChange = (e, newValue) => {
    let nValue = this.state.values[newValue].value;
    this.setState({ indexTab: this.getIndexTabWithParamValue(this.state.values), tabValue: nValue, value: nValue });
    if (this.props.onChangeSelect) this.props.onChangeSelect(nValue, this.props.paramObj.id);
    if (this.props.toSend) this.props.toSend(this.props.paramObj, nValue);
    //Call parent onchange
    if (this.props.onChange) {
      this.props.onChange('slider');
    }
  };

  handleDisabledClick = (disabled)=>{
    if(this.props.handleDisabledMessage && disabled){
        this.props.handleDisabledMessage(disabled);
    }
  }

  handleChangeIndex = (index) => {
    this.setState({
      index,
    });
  };

  render() {
    if (this.props.paramObj != null && !this.state.dataLoaded) {
      this.setState({
        dataLoaded: true
      });
    }

    //const handleChange = (evnt) => {
    //    this.setState({ value: evnt.target.value });
    //    if (this.props.onChangeSelect) this.props.onChangeSelect(evnt.target.value, this.props.paramObj.id);
    //    if (this.props.toSend) this.props.toSend(this.props.paramObj, evnt.target.value);
    //};

    function TabPanel(props) {
      const { children, value, index, ...other } = props;

      return (
        <div
          role='tabpanel'
          hidden={value !== index}
          id={`full-width-tabpanel-${index}`}
          aria-labelledby={`full-width-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box p={3}>
              <Typography component={'span'}>{children}</Typography>
            </Box>
          )}
        </div>
      );
    }

    TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired,
    };

    function a11yProps(index) {
      return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
      };
    }



    if (this.state.dataLoaded) {
      let disabled = this.props.paramObj.permissionType == 'R' || !this.props.paramObj.online;
      try {
        return (
          <div onClick={() => this.handleDisabledClick({readOnly: this.props.paramObj.permissionType == 'R', online: this.props.paramObj.online})}>
            <AppBar position='static' color='default'>
              <Tabs
                value={this.state.indexTab}
                onChange={this.handleChange}
                indicatorColor='primary'
                variant='fullWidth'
                className={disabled ? 'childCustomTabSelect tabSelect' : 'tabSelect'}
              >
                {this.state.values.map((value) => (
                      <Tab
                      key={value.value}
                      label={
                        <div className='minitabs'>
                          <h3 className='tabTitle'>{value.name}</h3>
                          {value.infoText && (
                            <div className='info-tooltip-parameter'>
                              <Tooltip title={value.infoText}>
                                <IconButton>
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                          )}
                        </div>
                      }
                      disabled={disabled}
                      {...a11yProps(value.value)}
                    />                  
                ))}
                </Tabs>
            </AppBar>
            <SwipeableViews
              axis={SetTheme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={this.state.indexTab}
              className='container'
            >
              {this.state.values.map((_value, key) => (
                <TabPanel
                  key={key}
                  value={this.state.tabValue}
                  index={this.state.indexTab}
                  dir={SetTheme.direction}
                  style={{ overflowX: 'hidden' }}
                />
              ))}
            </SwipeableViews>
          </div>
        );
      } catch (e) {
        return <Error errors={e} />;
      }
    } else {
      return (
        <div className='row loadingParent'>
          <div className='loadingImg'></div>
        </div>
      );
    }
  }
}
export default withTranslation()(CustomTabSelect);
