import React, { Component } from 'react';
import '../../../App.css';

import { withTranslation } from 'react-i18next';

import AuthenticationContext from '../../../Authentication/types/AuthContextType';
import InstallationsHttpClient from '../../../HttpClient/InstallationsHttpClient';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

class InstallationUserEdit extends Component {
  static contextType = AuthenticationContext;

  constructor(props) {
    super(props);
    this.id = this.props.id;
    this.state = {
      _data: {},
      gateways: 0,
      open: false,
      showModalDelete: false,
      expired: '',
      saving: false,
      role: '',
      roles: {},
      showModalError: false,
      errorMessage: '',
    };
    this.saveData = this.saveData.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);

    this.iu = {};
  }
  componentDidMount() {
    //this.invitation.id = this.props.invitationId;
    this.iu.installationId = this.props.installationId;
    this.iu.roleId = parseInt(this.props.roleSelected);
    this.iu.mail = this.props.mail;
    this.setState({ roleId: this.props.roleSelected });
    this.getRoles();

    this.setState({ iu: this.iu });
  }

  async saveData(e) {
    this.setState({ saving: true });
    //Modificamos los datos en this.installation para luego comparar con los datos del this.state.data y generar el patch
    e.preventDefault();
    try {
      let data;

      data = await this.SendPatch(this.props.userId, this.props.roleSelected, this.state.roleId);
    } catch (e) {}
  }

  async SendPatch(userId, roleId, newRoleId) {
    let data;
    try {
      this.http = new InstallationsHttpClient(this.context, this.installationId);
      data = await this.http.PatchUserInstallation(userId, roleId, newRoleId);

      if (data.status > 200) {
        this.setState({ saving: false, showModalError: true, errorMessage: data.title });
      } else {
        this.props.onSaveClose();
      }
    } catch (e) {
      console.log(e);
    }

    return data;
  }

  async deleteUserInstallation(id) {
    let data;

    this.http = new InstallationsHttpClient(this.context, this.id);
    data = await this.http.deleteUserInstallations(id);
    if (data.errors == null) {
      this.props.onSaveClose();
    } else {
      this.setState({ showModalError: true, errorMessage: data.errors });
    }
    return data;
  }
  async confirmDelete() {
    await this.deleteUserInstallation(this.state.data.id);
    this.setState({ showModalDelete: false });
  }

  handleChange = (event) => {
    this.setState({ roleId: event.target.value });
  };
  handleChangeExpired = (event) => {
    this.setState({ expired: event.target.value });
  };

  async getRoles() {
    this.http = new InstallationsHttpClient(this.context, this.id);
    let data = await this.http.getRoles();

    this.setState({ roles: data.content });
  }

  dropdownRoles() {
    const handleChange = (event) => {
      this.setState({ parentid: event.target.value });
    };
    const { t } = this.props;

    let dataLoaded = this.state.roles != undefined && this.state.roles.length > 0;
    let listitems = null;
    if (dataLoaded) {
      listitems = this.state.roles.map((item, i) => {
        return <MenuItem value={item.id}>{item.name}</MenuItem>;
      });

      return (
        <FormControl variant='standard'>
          <InputLabel id='roleLabel'>{t('invitation.role')}</InputLabel>

          {/* TODO: When this select gets implemented, the setCustomValidity has to be parched/fixed. Check task #6103 in Dev-Ops for more information.*/}
          <Select
            inputProps={{
              onInvalid: (event) => {
                event.target.setCustomValidity(t('general.error_required'));
              },
              onInput: (event) => {
                event.target.setCustomValidity(t('general.error_required'));
              },
            }}
            variant='standard'
            name='Role'
            className='titleProperty'
            labelId='roleLabel'
            id='ddlRoles'
            label='Roles'
            type='email'
            value={this.state.roleId != null ? this.state.roleId : ''}
            required
            onChange={this.handleChange}
          >
            {listitems}
          </Select>
        </FormControl>
      );
    }
  }

  render() {
    const { t } = this.props;

    const deleteInstallation = () => {
      this.setState({ showModalDelete: true });
    };

    const handleClose = () => {
      this.setState({ showModalDelete: false });
    };
    const handleCloseModal = () => {
      this.props.onSaveClose();
    };
    const handleCloseError = () => {
      this.setState({ showModal: false, showModalError: false });
    };

    let dataLoaded = true; //this.state.data != undefined && this.state.mySites != undefined;

    return (
      <React.Fragment>
        {dataLoaded ? (
          <div>
            <Dialog disableEscapeKeyDown open={this.state.showModalError} onClose={handleCloseError}>
              <DialogTitle>{t('productspages.productedit.erroredittitle')}</DialogTitle>
              <DialogContent>
                <div>
                  <span>{this.state.errorMessage}</span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button className='primary' onClick={handleCloseError}>
                  {t('actions.close')}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog disableEscapeKeyDown open={this.state.showModalDelete} onClose={handleClose}>
              <DialogTitle>{t('actions.deletename', { name: t('product') })}</DialogTitle>
              <DialogContent>
                <div>
                  <span>{t('actions.confirmdelete', { name: t('product') })}</span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button color='primary' onClick={this.confirmDelete}>
                  {t('actions.yes')}
                </Button>
                <Button color='primary' onClick={handleClose}>
                  {t('actions.no')}
                </Button>
              </DialogActions>
            </Dialog>
            <form id='divForm' onSubmit={(e) => this.saveData(e)}>
              <div className='form-row'>
                <div className='form-group col-md-6'>
                  <TextField
                    variant='standard'
                    id='txtNombre'
                    label='Nombre'
                    defaultValue={this.props.mail}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
                <div className='form-group col-md-6'>{this.dropdownRoles()}</div>
              </div>

              <div className='form-row'>
                <div className='form-group col-md-6'>
                  <Button type='submit' className='primary'>
                    {t('actions.save')}
                  </Button>
                </div>

                <div className='form-group col-md-6'>
                  <Button className='primary pull-right' onClick={handleCloseModal}>
                    {t('actions.close')}
                  </Button>
                </div>

                <div className='form-group col-md-12'>
                  {this.state.saving ? (
                    <div className='loadingParent'>
                      <div className='loadingImg'></div>
                    </div>
                  ) : null}
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className='loadingParent'>
            <div className='loadingImg'></div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation()(InstallationUserEdit);
