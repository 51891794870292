import { useEffect, useContext } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ErrorBoundary from '../src/ErrorBoundary';
import ConnectionStatus from './ConnectionStatus';
import LandingContainer from './components/Landing/container/LandingContainer';
import PowerBIContainer from './components/PowerBI/container/PowerBIContainer';
import Error from './components/Error';
import UserTabsContainer from './components/UsersComponents/container/UserTabsContainer';
import ManagementContainer from './components/Management/container/ManagementContainer';
import Home from './components/Home';
import Sites from './components/MenuPages/Sites';
import Site from './components/MenuPages/Site';
import Installations from './components/MenuPages/Installations';
import InstallationList from './components/InstallationComponents/InstallationList';
import BaseFilter from './components/FilterComponents/BaseFilter';
import FilterProducts from './components/MenuPages/FilterProducts';
import Products from './components/MenuPages/Products';
import GatewaysList from './components/GatewaysComponents/GatewaysList';
import GatewayDetail from './components/GatewaysComponents/GatewayDetail';
import Page from './components/Page';
import PageNoAccess from './components/PageNoAccess';
import ConfirmEmailContainer from './components/Register/container/ConfirmEmailContainer';
import ConfirmRegisterContainer from './components/Register/container/ConfirmRegisterContainer';
import WelcomeContainer from './components/Register/container/WelcomeContainer';
import DictionaryTool from './components/MenuPages/DictionaryTool';
import AuthenticationContext from '../src/Authentication/types/AuthContextType';

const App = () => {
  const context = useContext(AuthenticationContext);
  const location = useLocation();

  useEffect(() => {
    const favicon = document.getElementById('favIcon') as HTMLLinkElement | null;
    if (favicon && Number(context.brandCode) === 11) {
      favicon.href = 'https://www.laufen.com/o/laufen-theme/images/favicon.ico';
      document.title = 'Laufen';
    } else if (favicon && Number(context.brandCode) === 14) {
      favicon.href = 'https://www.roca.es/o/roca-theme/images/favicon.ico';
      document.title = 'Roca';
    }
  }, [context.brandCode]);

  const filterRoutes = () => {
    const path = [
      '/filter/installations',
      '/filter/generalFilter',
      '/productsFilter',
      '/filter/productsFilter',
      '/filter/gateways',
      '/productBI',
    ];
    return path.map((route) => <Route key={route} path={route} element={<BaseFilter />} />);
  };

  const welcomeRoutes = () => {
    const path = ['/welcome', '/welcomeRoca', '/welcomeLaufen'];
    return path.map((route) => <Route key={route} path={route} element={<WelcomeContainer />} />);
  };

  const homeRoutes = () => {
    const path = ['/', '/home', '/home/:action'];
    return path.map((route) => <Route key={route} path={route} element={<Home />} />);
  };

  return (
    <ErrorBoundary location={location}>
      <ConnectionStatus>
        <Routes>
          <Route path='/sites' element={<Sites />} />
          <Route path='/site/:id' element={<Site />} />
          <Route path='/installations' element={<InstallationList />} />
          <Route path='/installation/:id' element={<Installations />} />
          <Route path='/filterProducts' element={<FilterProducts />} />
          <Route path='/product/:id' element={<Products />} />
          <Route path='/gateways' element={<GatewaysList />} />
          <Route path='/gateway/:id' element={<GatewayDetail />} />
          <Route path='/page' element={<Page />} />
          <Route path='/page/:culture/:brandId/:pageId' element={<Page />} />
          <Route path='/user' element={<UserTabsContainer profileType='personal' />} />
          <Route path='/powerApps' element={<DictionaryTool />} />
          <Route path='/management' element={<ManagementContainer />} />
          <Route path='/detail/user/:id' element={<UserTabsContainer profileType='manage'/>} />
          <Route path='/productBI' element={<PowerBIContainer />} />
          <Route path='*' element={<Error errors={{ status: 404 }} />} />
          {homeRoutes()}
          {filterRoutes()}
          {welcomeRoutes()}
          {context.freeAccess ? (
            <>
              <Route path='/page' element={<PageNoAccess />} />
              <Route path='/register/confirm-email' element={<ConfirmEmailContainer />} />
              <Route path='/register/confirm-register' element={<ConfirmRegisterContainer />} />
              <Route path='/page/:culture/:brandId/:pageId' element={<PageNoAccess />} />
              <Route path='/' element={<LandingContainer />} />
              {welcomeRoutes()}
            </>
          ) : null}
        </Routes>
      </ConnectionStatus>
    </ErrorBoundary>
  );
};

export default withTranslation()(App);