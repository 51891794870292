import React, { Component } from 'react';
import '../../../../App.css';
import Parameters from '../../../ParametersComponents/Parameters';
import { withTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import NoConfiguration from '../../../NoConfiguration';
import ProductUpdatePackageForm from './ProductUpdatePackageForm';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

export class ProductsParameters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      serialNumber: null,
      productTelemetry: this.props.productTelemetry 
    };
    this.getSerialNumber = this.getSerialNumber.bind(this);
  }

  getSerialNumber(obj, val) {
    if (this.state.serialNumber == null && val != null) {
      this.setState({ serialNumber: val });
    }
  }

  updateParameters() {
    if (typeof this.props.updateParameters === 'function') {
      this.props.updateParameters();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.productTelemetry !== prevState.productTelemetry) {
      return { productTelemetry: nextProps.productTelemetry };
    }
    return null;
  }

  stopRefresh(val) {
    if (typeof this.props.stopRefresh === 'function') {
      this.props.stopRefresh(val);
    }
  }
  vRefresh(val) {
    if (typeof this.props.refresh === 'function') {
      this.props.refresh(val);
    }
  }

  openDialog = () => {
    this.setState({ show: true });
  };

  handleClose = (ev) => {
    this.setState({ show: false });
  };
  handleCloseModal = (data = null) => {
    const { t } = this.props;
    this.handleClose();
    if (data != null) {
      this.props.renderMessage('success', t('messages.successCommandSend'));
    }
  };

  render() {
    const { t } = this.props;

    let smartComponentTypesStr = '';
    if (this.props.parametersViewZones?.content?.length > 0) {
      smartComponentTypesStr = JSON.stringify(this.props.parametersViewZones.content[0]?.smartComponentTypes);
    }

    let productsUserParameters = smartComponentTypesStr?.includes('Products-User-Parameters');
    let productsTechnicalParameters = smartComponentTypesStr?.includes('Products-Technical-Parameters');

    return (
      <React.Fragment>
        <Dialog classes={{ paper: 'installationModal' }} disableEscapeKeyDown open={this.state.show} onClose={this.handleClose}>
          <DialogActions className='jc-spaceBetween'>
            <h2 className='mb-0 ml-4'>{t('actions.updatename', { name: 'Firmware' })}</h2>
            <IconButton onClick={this.handleClose}>
              <CloseIcon fontSize='large' />
            </IconButton>
          </DialogActions>
          <DialogContent>
            {/*<ProductsFirmwareUpdate product={this.props.product} />*/}
            <ProductUpdatePackageForm product={this.props.product} unmountMe={this.handleCloseModal.bind(this)} />
          </DialogContent>
        </Dialog>
        
        {productsUserParameters == true ? (
          <div
            style={{ paddingBottom: '15px', marginBottom: '20px', marginTop: '-16px' }}
            className='row card-container'
            id='userParameters'
          >
            <div className='w-100' style={{ display: 'flex' }}>
              <h3 style={{ marginLeft: '15px' }}>{t('parametersPage:titles.USER PARAMETERS')}</h3>{' '}
              <h6
                className={'col serialNumber' + (this.state.serialNumber == null ? ' d-none' : '')}
                style={{ textAlign: 'right' }}
              >
                {' '}
                {t('productspages.parameters.serialnumber')}: {this.state.serialNumber}
              </h6>
            </div>
            <Parameters
              cols='col-4'
            //   message={this.parentMessage.bind(this)}
              product={this.props.product}
              productTelemetry={this.state.productTelemetry}
              parametersViewZones={this.props.parametersViewZones}
              pageZonePosition='Products-User-Parameters'
              sendSerialNumber={this.getSerialNumber}
              updateParameters={this.updateParameters.bind(this)}
              stopRefresh={this.stopRefresh.bind(this)}
              refresh={this.vRefresh.bind(this)}
              renderMessage={this.props.renderMessage}
            />
          </div>
        ) : null}
        {productsTechnicalParameters == true ? (
          <div style={{ paddingBottom: '25px' }} className='row card-container' id='technicalParameters'>
            <h3 style={{ marginLeft: '15px' }}>{t('parametersPage:titles.TECHNICAL PARAMETERS')}</h3>
            <Parameters
              cols='col-4'
              product={this.props.product}
            //   message={this.parentMessage.bind(this)}
              productTelemetry={this.state.productTelemetry}
              parametersViewZones={this.props.parametersViewZones}
              pageZonePosition='Products-Technical-Parameters'
              sendSerialNumber={this.getSerialNumber}
              updateParameters={this.updateParameters.bind(this)}
              stopRefresh={this.stopRefresh.bind(this)}
              refresh={this.vRefresh.bind(this)}
              renderMessage={this.props.renderMessage}
            />
          </div>
        ) : null}
        <div style={{ paddingBottom: '15px', marginBottom: '20px', height: '55px', marginTop: '12px' }}>
          <Button className='primary pull-right float-right' onClick={this.openDialog}>
            {t('actions.updatename', { name: 'Firmware' })}
          </Button>
        </div>
        {!productsUserParameters && !productsTechnicalParameters ? <NoConfiguration /> : null}
      </React.Fragment>
    );
  }
}

export default withTranslation()(ProductsParameters);
