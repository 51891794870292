import { PureComponent } from 'react';
import AuthenticationContext from '../../../../Authentication/types/AuthContextType';
import ReactECharts from 'echarts-for-react';
import { t } from 'i18next';
import { getColor, sumArrays, compareGeneralLegend } from '../../utils/chartComponentsUtils';
import _ from 'lodash';
import moment from 'moment';
import Skeleton from '@mui/material/Skeleton';
import { Legend } from '../../types/ChartsComponents';
import { withTranslation } from 'react-i18next';
import EmptyBarChartView from '../../views/EmptyBarChartView';

class BarChartContainer extends PureComponent<any, any> {
  static contextType = AuthenticationContext;
  constructor(props: any) {
    super(props);
    this.state = {
      chartsData: false,
      dataPoints: false,
      chartOptions: false,
      initialChartOptions: false,
      chartLegend: false,
      error: false,
    };
  }

  componentDidMount = async () => {
    if (this.props.chart && !this.state.dataPoints && !this.state.chartsData) {
      await this.getDataPoints();
    }
  };

  componentDidUpdate(_prevProps, prevState) {
    if (this.props.generalLegend) {
      // general sites use case
      if (!_.isEmpty(this.state.chartLegend) && !_.isEmpty(this.props.generalLegend)) {
        const newLegend = compareGeneralLegend(this.props.generalLegend, this.state.chartLegend);
        if (newLegend) {
          this.applyLegendFilterToChartData(newLegend);
        }
      }
    } else {
      // normal use case
      const legendFinished = Object.keys(this.state.chartLegend).length === Object.keys(prevState.chartLegend).length;
      if (legendFinished && !_.isEqual(this.state.chartLegend, prevState.chartLegend)) {
        this.applyLegendFilterToChartData(this.state.chartLegend);
      }
    }
  }

  applyLegendFilterToChartData = (updatedLegend) => {
    const chartOptions = { ..._.cloneDeep(this.state.initialChartOptions), series: [] };
    // Filter and add the series according to the legend filter
    if (updatedLegend) {
      chartOptions.series = this.state.initialChartOptions.series.filter((serie) => {
        return updatedLegend[serie.name]?.status === true;
      });
    }
    // Update state and force re-render
    this.setState({ chartOptions: false }, () => {
      this.setState({ chartOptions, chartLegend: updatedLegend });
    });
  };

  setXaxis = (item, daysDiff) => {
    switch (this.props.chart.groupByEcharts) {
      case 'day':
        return moment.utc(item.key).format('HH');
      case 'year':
        return moment.utc(item.key).format('MMM');
      case 'monthWeek':
        let beginWeek = moment.utc(item.key);
        let endWeek = moment.utc(item.key).add(6, 'days');
        return beginWeek.format('DD MMM') + ' - ' + endWeek.format('DD MMM');
      default:
        if (daysDiff < 1) {
          return moment.utc(item.key).format('HH');
        } else if (daysDiff < 5) {
          return moment.utc(item.key).format('DD MMM (HH:00)');
        } else {
          return moment.utc(item.key).format('DD MMM');
        }
    }
  };

  setStackedKpis = async (series, reference) => {
    const stackedData: any[] = [];
    const seriesData: any[] = _.cloneDeep(series);
    const referenceData: any[] = _.cloneDeep(reference);
    for (let kpiStacked of this.props.chart.kpisStacked) {
      let stacked: any = {};
      let responseKpiStacked = await this.props.chartRepository.installationKPIDatapoints(kpiStacked);

      stacked.name = kpiStacked.title;
      if (responseKpiStacked.status == 200) {
        if (responseKpiStacked.data.content && responseKpiStacked.data.content) {
          stacked.values = [];
          stacked.colors = getColor(kpiStacked.color);
          stacked.reference = null;
          for (let dataKpiStacked of responseKpiStacked.data.content) {
            if (dataKpiStacked) {
              let val = dataKpiStacked.value;
              if (!dataKpiStacked.value) {
                val = 0;
              } else {
                dataKpiStacked.value.forEach((element, index) => {
                  dataKpiStacked.value[index] = element.value ? element.value : 0;
                });
              }
              stacked.values.unshift(val);
            }
          }
          let bar = {
            name: t('charts:titles.' + stacked.name),
            type: 'bar',
            stack: 'Ad',
            itemStyle: {
              color: stacked.colors.color,
            },
            data: stacked.values,
          };
          seriesData.push(bar);

          if (stacked.reference) {
            referenceData.push(stacked.reference.values);
          }
          stackedData.unshift(stacked);
        }
      }
    }
    return { stacked: stackedData, series: seriesData, reference: referenceData };
  };

  units;
  getDataPoints = async () => {
    try {
      const response = await this.props.chartRepository.manageChartType(this.props.chart);
      const dataPoints = response?.content;
      const deviceUnits = response?.deviceUnits;
      this.units = deviceUnits && deviceUnits != '' ? deviceUnits : this.props.chart.units      
      if (!dataPoints) {
        this.setState({
          error: {
            type: 'call',
            message: t('translation:somethingWrong'),
          },
        });
        return null;
      } else if (!dataPoints.length) {
        this.setState({
          error: {
            type: 'empty',
            message: t('translation:nodata'),
          },
        });
        return null;
      }

      let chartOptions = _.cloneDeep(this.props.chart.options);
      const endRange = moment(this.props.chart.dateRange[1]);
      const beginRange = moment(this.props.chart.dateRange[0]);
      const daysDiff = endRange.diff(beginRange, 'days');

      let chartsData: any[] = [];
      let xAxis: any[] = [];
      let series: any[] = [];
      let reference: any[] = [];

      for (const [dataPointIndex, dataPoint] of dataPoints.entries()) {
        let color = dataPoint.color;
        let chartData: any = { name: dataPoint.name, colors: getColor(color), data: [] };
        if (dataPoint.value) {
          // set chartData value
          dataPoint.value.forEach((item) => {
            if (item.value) {
              chartData.data.unshift(item.value as never);
            } else {
              chartData.data.unshift(0 as never);
            }
            if (dataPointIndex === 0) {
              const xaxiItem = this.setXaxis(item, daysDiff);
              xAxis.unshift(xaxiItem);
            }
          });

          // KpisStacked useCase
          if (this.props.chart.kpisStacked) {
            const stackedData = await this.setStackedKpis(series, reference);
            chartData.stacked = stackedData.stacked;
            series = stackedData.series;
            reference = stackedData.reference;
          }
        }

        let bar = {
          name: !this.props.legendTranslate ? chartData.name : t('charts:titles.' + chartData.name),
          type: 'bar',
          stack: 'Ad',
          itemStyle: {
            color: chartData.colors.color,
          },
          data: chartData.data,
        };
        series.push(bar);

        // Reference useCase
        if (chartData.reference) {
          reference.push(chartData.reference);
          let nameRef = t('charts:titles.' + chartData.nameReference);
          let line = {
            name: nameRef,
            type: 'line',
            showSymbol: false,
            smooth: true,
            stack: 'average',
            lineStyle: {
              width: 0.5,
            },
            areaStyle: {
              opacity: 0.8,
            },
            itemStyle: {
              color: 'rgb(227, 228, 229)',
            },
            data: sumArrays(chartData.data.length, reference),
          };
          series.push(line);
        }

        // When is day fix time with :00
        if (this.props.chart.groupByEcharts === 'Day' || daysDiff < 1) {
          chartOptions.tooltip['axisPointer'] = {
            label: {
              formatter: '{value}:00',
            },
          };
        }

        chartOptions.xAxis.data = xAxis;
        chartOptions.xAxis.name = t('charts:axisTitles.' + this.props.chart.granularity.toLowerCase() + 's');
        chartOptions.yAxis.name = t('charts:axisTitles.' + this.units.toLowerCase());
        chartOptions.series = series;
        delete chartOptions.title;
        chartsData.push(chartData);
      }

      const chartLegend = this.props.chartRepository.manageLegend(dataPoints);
      if (this.props.chart.siteReportingPage) {
        this.props.getLegendFromChart(chartLegend);
      }

      this.setState({
        dataPoints,
        chartsData,
        chartOptions,
        initialChartOptions: chartOptions,
        chartLegend,
      });
    } catch (error: any) {
      this.setState({
        error: {
          type: 'call',
          message: error?.message ? error?.message : error,
        },
      });
    }
  };

  renderLegend = (chartLegend: Legend) => {
    if (!Object.keys(chartLegend).length) return null;
    const sortedLegend = Object.keys(chartLegend).sort((a, b) => a.localeCompare(b));
    return (
      <div className='new-legend'>
        {sortedLegend.map((itemName, key) => {
          const item = chartLegend[itemName];
          return (
            <div className={`child ${!item.status && 'notselected'}`} key={key} onClick={() => this.legendHandler(itemName)}>
              <div className='color' style={{ backgroundColor: item.color }}></div>
              <span className='t2'>{itemName}</span>
            </div>
          );
        })}
      </div>
    );
  };

  legendHandler = (itemName: string) => {
    const newLegend = {
      ...this.state.chartLegend,
      [itemName]: { ...this.state.chartLegend[itemName], status: !this.state.chartLegend[itemName].status },
    };
    this.setState({ chartLegend: newLegend });
  };

  render() {
    const { error, chartsData, chartOptions, dataPoints } = this.state;
    const { title, siteReportingPage, hiddenLegend } = this.props.chart;

    return (
      <div className={`chart-group`}>
        <div className={`title header-chart-group ${error ? 'empty' : ''}`}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {!siteReportingPage && <h4>{t('charts:titles.' + title)}</h4>}
          </div>
          {!siteReportingPage && !hiddenLegend && !error && this.renderLegend(this.state.chartLegend)}
        </div>
        <div className='bar-chart-box'>
          {error ? (
            <EmptyBarChartView {...error} />
          ) : chartsData.length && chartOptions ? (
            <ReactECharts style={{ height: '500px', width: '100%', padding: '10px' }} option={chartOptions} />
          ) : (
            !dataPoints.length && (
              // <Skeleton animation='wave' variant='rectangular' width={638} height={500} style={{ margin: '0 auto' }} />
              <div className='row loadingParent'>
                <div className='loadingImg'></div>
              </div>
            )
          )}
        </div>
      </div>
    );
  }
}
export default withTranslation()(BarChartContainer);
