import React, { useMemo, useState, useEffect } from 'react';
import {
  AzureMap,
  AzureMapDataSourceProvider,
  AzureMapFeature,
  AzureMapLayerProvider,
  AzureMapsProvider,
} from 'react-azure-maps';
import { AuthenticationType, data } from 'azure-maps-control';

const memoizedOptions = {
  textOptions: {
    textField: ['get', 'title'], //Specify the property name that contains the text you want to appear with the symbol.
    offset: [0, 1.2],
  },
};



const renderPoint = (coordinates)  => {
  const rendId = Math.random();

  return (
    <AzureMapFeature
      key={rendId}
      id={rendId.toString()}
      type="Point"
      coordinate={coordinates}
    />
  );
};

const SinglePointMap = (props) => {

    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    });

    const longitude = props.installation.longitude;
    const latitude = props.installation.latitude;

    const point = (longitude && !isNaN(longitude)) ? [new data.Position(longitude, latitude)] : [];

    let center = [0,0]

    if (point.length > 0) {
        center = point[0]
    }
    else if (props.startLocation) {
        center = props.startLocation
    }


    const [markers, setMarkers] = useState(point);
    const [markersLayer] = useState('SymbolLayer');
    const [layerOptions] = useState(memoizedOptions);

    const option = {
        center: center,
        zoom: 9,
        view: 'Auto',
        authOptions: {
            authType: AuthenticationType.subscriptionKey,
            subscriptionKey: 'xlUv8b1iNmVh1uccKVhZhV44rlryS6vl9Yx6mto8mwo'
        },
        showFeedbackLink: false,
        showLogo: false
    };

    const memoizedMarkerRender = useMemo( () => markers.map((marker) => renderPoint(marker)), [markers], );

    const addPoint = (e) => {

        if (props.onMapClick) {
            props.onMapClick(e);
            const newPoint = new data.Position(e.position[0], e.position[1]);
            setMarkers([newPoint]);
        }

    }

    let height = props.height ? props.height : "150px"

    return (<>
        
        <AzureMapsProvider>
            <div id="azureMaps" className="azureMaps" style={{ height: height }}>
                <AzureMap options={option} events={{ click: addPoint}}>
                    <AzureMapDataSourceProvider id={'singlePointMap AzureMapDataSourceProvider'} options={{ cluster: true, clusterRadius: 2 }}>
                        <AzureMapLayerProvider  id={'singlePointMap AzureMapLayerProvider'} options={layerOptions} type={markersLayer} />
                        {memoizedMarkerRender}
                    </AzureMapDataSourceProvider>
                </AzureMap>
            </div>
        </AzureMapsProvider>
        
    </> );
};
export default SinglePointMap;