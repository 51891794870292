import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import Moment from 'react-moment';
import InvitationsOutbox from '../../Invitations/InvitationsOutbox';
import InstallationsHttpClient from '../../../HttpClient/InstallationsHttpClient';
import UserHttpClient from '../../../HttpClient/HttpClient';

import AuthenticationContext from '../../../Authentication/types/AuthContextType';
import UserInstallationEdit from '../Tabs/InstallationUserEdit';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Message from '../../Message/Message';

import Table from '../../CustomComponents/ReactTable/Table';
import '../Installations.css';
import { withRouter } from '../../../components/withRouter';
import { manageInfoUser } from '../../Common/utils/helpers';

class InstallationUsers extends Component {
  static contextType = AuthenticationContext;
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 5,
      status: '',
      openConfirmDelete: false,
      _data: [{}],
      showForm: false,
      showDelete: false,
      message: null,
    };
    this.id = parseInt(window.location.pathname.split('/')[2]);

    this.InvitationsOutboxVisible = false;

    this.paginaActual = 1;

    const { t } = props;

    this.columns = [
      {
        dataField: 'userId',
        text: t('tables.userId'),
        align: 'left',
        sort: true,
        hidden: true,
        headerStyle: () => {
          return { width: '0%' };
        },
      },
      {
        dataField: 'userMail',
        text: t('invitation.mail'),
        align: 'left',
        sort: true,
        formatter: this.userFormatter.bind(this),
        headerStyle: () => {
          return { width: '26%' };
        },
      },
      {
        dataField: 'role',
        text: t('invitation.role'),
        align: 'left',
        sort: true,
        headerStyle: () => {
          return { width: '18%' };
        },
      },
      {
        dataField: 'invited',
        text: t('invitation.invited'),
        align: 'left',
        sort: true,
        formatter: this.dateFormatterInvited.bind(this),
        headerStyle: () => {
          return { width: '18%' };
        },
      },
      {
        dataField: 'signUp',
        text: t('general.signUp'),
        align: 'left',
        sort: true,
        formatter: this.dateFormatter.bind(this),
        headerStyle: () => {
          return { width: '18%' };
        },
      },
      {
        dataField: 'DeleteFormatter',
        text: 'Actions',
        align: 'left',
        hidden: false,
        formatter: this.DeleteFormatter.bind(this),
        headerStyle: () => {
          return { width: '10%' };
        },
      },
    ];

    this.customTotal = (from, to, size) => (
      <span className='react-bootstrap-table-pagination-total' style={{ marginLeft: '5px' }}>
        {t('tables.customTotal', { from: from, to: to, size: size })}
      </span>
    );

    this.defaultSorted = [
      {
        dataField: 'date',
        order: 'desc',
      },
    ];

    this.confirmDelete = this.confirmDelete.bind(this);
  }

  renderMessage = (type, content) => {
    this.setState({
      message: {
        type: type,
        content: content,
      },
    });
  };

  handleDialogClose = () => {
    this.setState({ message: null });
  };

  userFormatter({ row, value }) {
    if (
      (this.props.data.hasOwnerAccess || this.state.parent?.hasOwnerAccess) &&
      this.me?.userId.toString() == row.userId.toString()
    ) {
      return (
        <span>
          <img
            src='https://strgpiotcoredev.blob.core.windows.net/pictos-by-product/key.svg'
            style={{ height: '15px', marginRight: '5px' }}
          />{' '}
          {row.userMail}{' '}
        </span>
      );
    } else {
      return <span style={{ marginLeft: '20px' }}>{row.userMail}</span>;
    }
  }
  EditFormatter({ row, value }) {
    const onClick = (evt) => {
      evt.preventDefault();

      this.setState({
        showForm: true,
        role: evt.currentTarget.getAttribute('data-role'),
        userId: evt.currentTarget.getAttribute('data-user'),
        mail: evt.currentTarget.getAttribute('data-mail'),
      });
    };

    var idEle = 'btnEdit_' + row.userID;
    return (
      <EditOutlinedIcon
        id={idEle}
        style={{ width: '24px', cursor: 'pointer' }}
        onClick={(evt) => onClick(evt)}
        data-role={row.roleId}
        data-user={row.userID}
        data-mail={row.userMail}
      />
    );
  }

  dateFormatterInvited({ row, value }) {
    var id = 'custom-switch_' + row.id;

    if (row.invited == null) {
      return <span></span>;
    } else if (row.invited !== null && row.invited.startsWith('0001-01-01')) {
      return <span></span>;
    } else {
      return (
        <Moment format='DD/MM/YYYY' withTitle locale='es'>
          {value}
        </Moment>
      );
    }
  }
  dateFormatter({ row, value }) {
    var id = 'custom-switch_' + row.id;

    return (
      <Moment format='DD/MM/YYYY' withTitle locale='es'>
        {value}
      </Moment>
    );
  }

  DeleteFormatter({ row, value }) {
    const onClick = (evt) => {
      this.setState({ showDelete: true, userId: row.userId });
    };

    var idEle = 'btnDelete_' + row.userId;

    if (
      (this.props.data.hasOwnerAccess || this.state.parent?.hasOwnerAccess) &&
      this.me?.userId.toString() != row.userId.toString()
    ) {
      if (this.state.parent?.userId == row.userId) {
        return null;
      } else {
        return (
          <DeleteOutlineIcon
            id={idEle}
            style={{ width: '24px', cursor: 'pointer' }}
            onClick={(evt) => onClick(evt)}
            data-user={row.userId}
          />
        );
      }
    } else if (
      !this.props.data.hasOwnerAccess &&
      !this.state.parent?.hasOwnerAccess &&
      this.me?.userId.toString() == row.userId.toString()
    ) {
      return (
        <DeleteOutlineIcon
          id={idEle}
          style={{ width: '24px', cursor: 'pointer' }}
          onClick={(evt) => onClick(evt)}
          data-user={row.userId}
        />
      );
    } else {
      return null;
    }
  }

  async deleteUserInstallation(installationId, userId, t) {
    if (this.state.userId.toString() == this.me?.userId) {
      this.http = new InstallationsHttpClient(this.context, this.id);
      let data = await this.http.deleteUserInstallations(this.id, this.state.userId);

        this.props.navigate("/filter/installations");
    } else {
      this.http = new InstallationsHttpClient(this.context, this.id);
      let data = await this.http.deleteUserInstallations(this.id, this.state.userId);
      let messageObj = {};
      if (data.content != undefined && data.content) {
        messageObj.message = t('installationspages.userinstallation.deleteUserInstallationSuccess');
        messageObj.type = 'success';
      } else if (data.status != undefined) {
        if (data.status > 200) {
          messageObj.message = data.title == undefined ? t('installationspages.userinstallation.forbid') : data.title;
          messageObj.type = 'error';
        }
      } else if (data.errors != undefined) {
        messageObj.message = data.title == undefined ? t('installationspages.userinstallation.forbid') : data.errors[0];
        messageObj.type = 'error';
      } else if (data.startsWith('System.InvalidOperation')) {
        messageObj.message = data.title == undefined ? t('installationspages.userinstallation.forbid') : data.title;
        messageObj.type = 'error';
      }

      this.setState({ saving: false, showDelete: false, userInstallations: null }, () => {
        this.getInstallationUsers();
      });
      if (messageObj.type) {
        this.renderMessage(messageObj.type, messageObj.message);
      }
    }
  }

  async confirmDelete(t) {
    await this.deleteUserInstallation(this.id, this.state.userId, t);
  }

  async getInstallationUsers() {
    this.http = new InstallationsHttpClient(this.context, this.id);
    let response = await this.http.InstallationGetInstallationsById();
    if (response.status == 200) {
      let data = response.data;
      this.setState({ userId: data.content.userId });
      this.setState({ userInstallations: data.content.userInstallations });
    }
  }

  async getParentSite() {
    this.httpParent = new InstallationsHttpClient(this.context, this.props.data.parentId);
    let response = await this.httpParent.InstallationGetInstallationsById();
    if (response.status == 200) {
      let data = response.data;
      this.setState({ parent: data.content });
      console.log(JSON.stringify(data.content));
    }
  }

  async getMe() {
    const userBasicData = await manageInfoUser(this.context, "userBasicData");
    if (userBasicData){
      return userBasicData;
    }else {
      this.setState({ showSendTransferOwnership: false });
      this.renderMessage('error', t('messages.errorGettingUser'));
    }
  }

  async componentDidMount() {
    this.getInstallationUsers();

    if (this.props.data.parentId != undefined) {
      this.getParentSite();
    }

    this.httpUser = new UserHttpClient(this.context);
    this.me = await this.getMe();
  }

  async componentWillMount() {
    this.getInstallationUsers();

    if (this.props.data.parentId != undefined) {
      this.getParentSite();
    }

    this.httpUser = new UserHttpClient(this.context);
    this.me = await this.getMe();
  }

  render() {
    const { t } = this.props;

    const pageButtonRenderer = ({ page, active, onPageChange }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      const activeStyle = {};
      const isActive = active ? 'active' : '';
      if (active) {
        activeStyle.margin = '5px';
        activeStyle.fontFamily = 'roboto-bold';
      } else {
        activeStyle.margin = '5px';
        activeStyle.fontFamily = 'roboto-light';
      }
      if (typeof page === 'string') {
        activeStyle.margin = '5px';
        activeStyle.fontFamily = 'roboto-light';
      }
      return (
        <li className={'page-item paginate_button ' + isActive}>
          <a href='#' className='page-link' onClick={handleClick} style={activeStyle}>
            {page}
          </a>
        </li>
      );
    };

    var dataLoaded;
    dataLoaded = this.state.userInstallations != undefined;

    if (dataLoaded) {
      this.options = {
        page: this.paginaActual,
        pageButtonRenderer,
        paginationSize: this.state.sizePerPage,
        pageStartIndex: 1,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        paginationTotalRenderer: this.customTotal,
        disablePageTitle: true,
        sizePerPageList: [
          {
            text: '5',
            value: 5,
          },
          {
            text: '10',
            value: 10,
          },
          {
            text: t('tables.all'),
            value: this.state.userInstallations.length,
          },
        ],
        onSizePerPageChange: (sizePerPage, page) => {
          this.setState({ sizePerPage: sizePerPage }, () => console.log('this.state.sizePerPage' + this.state.sizePerPage));
        },
        onPageChange: (newPage) => {
          this.paginaActual = newPage;
          this.setState({ page: newPage });
        },
      };

      if (this.props.data.hasOwnerAccess || this.state.parent?.hasOwnerAccess) {
        this.InvitationsOutboxVisible = true;
      }
    }

    const handleClose = () => {
      this.getInstallationUsers();
      this.setState({ showForm: false });
    };
    const handleCloseDelete = () => {
      this.setState({ showDelete: false });
    };

    return (
      <React.Fragment>
        {dataLoaded ? (
          <div>
            {this.state.message && <Message {...this.state.message} onClose={this.handleDialogClose} />}
            <Dialog disableEscapeKeyDown open={this.state.showDelete} onClose={handleCloseDelete}>
              <DialogTitle>{t('installationspages.userinstallation.deletequestiontitle')}</DialogTitle>
              <DialogContent>
                <div>
                  <span>{t('installationspages.userinstallation.deletequestion')}</span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button className='primary pull-right' onClick={() => this.confirmDelete(t)}>
                  {t('actions.yes')}
                </Button>
                <Button className='primary pull-right' onClick={handleCloseDelete}>
                  {t('actions.no')}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog disableEscapeKeyDown={false} open={this.state.showForm} onClose={() => handleClose()}>
              <DialogTitle>{t('actions.editname', { name: t('user') })}</DialogTitle>
              <DialogContent>
                <div id='divForm'>
                  <div className='form-row'>
                    <div className='form-group col-md-12'>
                      <UserInstallationEdit
                        installationId={this.id}
                        roleSelected={this.state.role}
                        userId={this.state.userId}
                        mail={this.state.mail}
                        onSaveClose={handleClose}
                      ></UserInstallationEdit>
                    </div>
                  </div>
                </div>
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>
            <div className='row prodChildNoZoom mb-5 userManagementTable'>
                <div className='row col-12'>
                  <h3>
                    {t('installationspages.usermanagement')} / {this.props.data.name}
                  </h3>
                </div>
               
                  <div className='col chartContainer'>
                    <Table
                      name={'InstallationUsers-locales'}
                      t={t}
                      resize={true}
                      columns={this.columns}
                      rows={this.state.userInstallations}
                      search={true}
                      page={this.state.page}
                      pageSize={this.state.sizePerPage}
                    />
                </div>
            </div>
            {this.InvitationsOutboxVisible ? (
              <div className='row prodChildNoZoom userManagementInvitations'>
                <InvitationsOutbox id={this.id} installationImage={this.props.data.avatarUri} />
              </div>
            ) : null}
          </div>
        ) : (
          <div className='loadingParent'>
            <div className='loadingImg'></div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
export default withRouter(withTranslation()(InstallationUsers));
