import React, { Component } from 'react';
import '../../../App.css';
import '../../ProductsStyle.css';
import { withTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import SinglePointMap from './../../SinglePointMap';
import NoData from '../../NoData';
import AuthenticationContext from '../../../Authentication/types/AuthContextType';
import InstallationsHttpClient from '../../../HttpClient/InstallationsHttpClient';
import SitehttpClient from '../../../HttpClient/SitesHttpClient';
import HttpClient from '../../../HttpClient/HttpClient';
import Form from 'react-bootstrap/Form';

import * as jsonpatch from 'fast-json-patch/index.mjs';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Message from './../../Message/Message';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

class InstallationsSummaryEdit extends Component {
  static contextType = AuthenticationContext;

  constructor(props) {
    super(props);
    this.id = this.props.id;

    this.type = props.type;

    this.state = {
      _data: {},
      gateways: 0,
      open: false,
      showModalDelete: false,
      avatarImg: '',
      showModalDeleteError: false,
      saving: false,
      showModalError: false,
      message: null,
      loadingSite: false,
      openConfirmDelete: false,
      disableAll: false,
      hMap: '100%',
      wMap: '95%',
      chartcolor: '#007DBA',
      selectedTimezone : null,
      _timezones: {},
      _errors: null
      };

    this.saveData = this.saveData.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.handleCloseConfirm = this.handleCloseConfirm.bind(this);
    this.deleteAvatar = this.deleteAvatar.bind(this);
    this.installation = {};
    this.originalInstallation = {};
    this.refresh = null;
  }
  componentDidMount() {
    //if (this.props.mode != "add") {
      this.getInstallation();
      this.getTimezones();
    //}
    //Los datos pueden venir de las props o tener que consultarlos si se refresca el componente por una navegación
  }

  componentDidUpdate(prevProps) {
    if (this.props.mode != 'add') {
      let idInstallation = parseInt(this.props.id);
      if (idInstallation != this.id) {
        this.id = idInstallation;
        this.httpInstallation = new InstallationsHttpClient(this.context, this.id, null, null);
        // obtenemos el valor del gateway
        this.getInstallation();
        this.getTimezones();
      }
    }
  }

  renderMessage = (type, content) => {
    this.setState({
      message: {
        type: type,
        content: content,
      },
    });
  };

  handleDialogClose = () => {
    this.setState({ message: null });
  };

  async getInstallation() {
    if (this.id != 0) {
      this.http = new InstallationsHttpClient(this.context, this.id);
      let response = await this.http.InstallationGetInstallationsById();
      this.httpSite = new SitehttpClient(this.context, this.id);
      let responseSite = await this.httpSite.GetSite();
      if (response.status == 200) {
        let data = response.data;
        if (this.props.mode != 'add') {
          this.redirectId = data.content.siteId ?? data.content.parentId;

          this.installation.name = data.content.name;
          this.installation.description = data.content.description;
          this.installation.gender = data.content.gender;
          this.installation.area = data.content.area;
          this.installation.environment = data.content.environment;
          this.installation.type = data.content.type;
          this.genderValue = data.content.gender;
          this.areaValue = data.content.area;
          this.environmentValue = data.content.environment;
          this.typeValue = data.content.type;
          this.installation.address = data.content.address;
          this.installation.latitude = data.content.latitude;
          this.installation.longitude = data.content.longitude;
          this.installation.postalCode = data.content.postalCode;
          this.installation.city = data.content.city;
          this.installation.country = data.content.country;
          this.installation.letter = data.content.letter;
          this.installation.number = data.content.number;
          this.installation.managerName = data.content.managerName;
          this.installation.managerMail = data.content.managerMail;
          this.installation.managerPhone = data.content.managerPhone;
          this.installation.parentId = data.content.parentId;
          this.installation.siteId = data.content.siteId;
          this.installation.chartsColor = data.content.chartsColor;
            this.state.chartcolor = data.content.chartsColor;

            if (this.type == 'site') {
                this.installation.timezone = data.content.timeZone.timeZoneId;
                this.state.selectedTimezone = data.content.timeZone.timeZoneId;
            }

          this.installation.id = data.content.id;
          this.setState({ data: this.installation, avatarImg: data.content.avatarUri });
        } else if (responseSite.status == 200) {
          let data = responseSite.data.content;

          this.installation.name = '';
          this.installation.description = '';
          this.installation.gender = '';
          this.installation.area = '';
          this.installation.environment = '';
          this.installation.type = '';
          this.installation.address = data.address;
          this.installation.latitude = data.latitude;
          this.installation.longitude = data.longitude;
          this.installation.postalCode = data.postalCode;
          this.installation.city = data.city;
          this.installation.country = data.country;
          this.installation.managerName = data.managerName;
          this.installation.managerMail = data.managerMail;
          this.installation.managerPhone = data.managerPhone;
          this.installation.timeZone = '';
          this.installation.letter = '';
          this.installation.number = '';
          this.installation.chartsColor = '';
          this.state.chartcolor = '#007DBA';

          if (!this.props.createSite) {
            this.installation.siteId = data.siteId;
            this.installation.parentId = this.props.id;
          }

          this.setState({ data: this.installation, avatarImg: '' });
        }

        this.originalInstallation = Object.assign({}, this.installation);
      }
    } else {
      this.installation.name = '';
      this.installation.description = '';
      this.installation.gender = '';
      this.installation.area = '';
      this.installation.environment = '';
      this.installation.type = '';
      this.installation.address = '';
      this.installation.latitude = 0;
      this.installation.longitude = 0;
      this.installation.postalCode = '';
      this.installation.city = '';
      this.installation.country = '';
      this.installation.managerName = '';
      this.installation.managerMail = '';
      this.installation.managerPhone = '';
      this.installation.timeZone = '';
      this.installation.letter = '';
      this.installation.number = '';
      this.installation.chartsColor = '';
      this.setState({ data: this.installation, avatarImg: '' });
      this.state.chartcolor = '#007DBA';
    }
  }

  async saveData(e, t) {
    this.setState({ saving: true });
    e.preventDefault();
    let name = document.getElementById('txtName').value;
    let number = document.getElementById('txtNumber').value;
    let letter = document.getElementById('txtLetter').value;
    let desc = document.getElementById('txtDescription').value;
    let address = document.getElementById('txtAddress').value;
    let latitude = document.getElementById('txtLatitude').value;
    let longitude = document.getElementById('txtLongitude').value;
    let postalCode = document.getElementById('txtPostalCode').value;
    let city = document.getElementById('txtCity').value;
    let country = document.getElementById('txtCountry').value;
    let responsableName = document.getElementById('txtResponsableName').value;
    let responsaleMail = document.getElementById('txtResponsableEmail').value;
    let responsablePhone = document.getElementById('txtResponsablePhone').value;
    let chartsColor = document.getElementById('inputchartcolor').value;
    let selectedTimezone = this.state.selectedTimezone;

    let gender = null;
    let area = null;
    let environment = null;
    let type = null;

    if (this.type == 'installation') {
      gender = this.genderValue;
      area = this.areaValue;
      environment = this.environmentValue;
      type = this.typeValue;
    }

    let installation;
    this.installation = {};
    this.installation.name = name;
    this.installation.description = desc;
    this.installation.gender = gender;
    this.installation.area = area;
    this.installation.environment = environment;
    this.installation.type = type;
    this.installation.address = address;
    this.installation.latitude = parseFloat(latitude);
    this.installation.longitude = parseFloat(longitude);
    this.installation.postalCode = postalCode;
    this.installation.city = city;
    this.installation.country = country;
    this.installation.managerName = responsableName;
    this.installation.managerMail = responsaleMail;
    this.installation.managerPhone = responsablePhone;
    this.installation.parentId = this.state.data.parentId;
    this.installation.siteId = this.state.data.siteId;
    this.installation.letter = letter;
    this.installation.number = parseInt(number);
    this.installation.chartsColor = chartsColor;
    this.installation.timeZone = selectedTimezone;

    if (this.props.mode != 'add') {
      this.installation.id = parseInt(this.id);
      var patch = jsonpatch.compare(this.originalInstallation, this.installation);
      this.installation = {};
      this.installation.id = this.id;
      this.installation.data = patch;
    }

    const formulario = new FormData();
    formulario.append('patchoptions', JSON.stringify(this.installation));
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    let data;
    switch (this.props.mode) {
      case 'add':
        data = await this.SendPost(formulario);
        break;
      case 'edit':
        data = await this.SendPatch(formulario);
        break;
      case 'delete':
        break;
    }

    if (data.status > 200) {
      this.setState({
        saving: false,
        messageError: t('installationspages.installationsummaryedit.errormessage') + ': ' + data.title,
        showModalDeleteError: true,
      });
    } else {
      if (this.state.status == 'change') this.uploadFile(false, data.content.id);

      this.props.onSaveClose({ id: data.content.id });
    }
  }

  async SendPatch(formulario) {
    let data;
    this.http = new InstallationsHttpClient(this.context, this.id);
    data = await this.http.PatchInstallations(formulario);
    return data;
  }

  async SendPost(formulario) {
    let data;

    this.http = new InstallationsHttpClient(this.context, this.id);
    if (!this.props.createSite) {
      data = await this.http.PostInstallations(formulario);
    } else {
      this.http = new SitehttpClient(this.context, this.id);
      data = await this.http.PostSite(formulario);
    }

    return data;
  }

  async deleteInstallation(id, t) {
    let data;
    try {
      this.http = new InstallationsHttpClient(this.context, this.id);

      var name = document.getElementById('txtNameDelete').value;

      if (name.toLowerCase() == this.installation.name.toLowerCase()) {
        data = await this.http.DeleteInstallations(id, name);
        if (data.content != undefined && data.content) {
          if (!this.props.createSite && this.redirectId != null) {
            document.getElementById('redirectToParent').click();
          } else if (window.location.href.includes('/installation')) {
            document.getElementById('redirect').click();
          } else if (window.location.href.includes('/site')) {
            document.getElementById('redirectsite').click();
          } else {
            this.props.onSaveClose();
          }
        } else if (data.status != undefined) {
          if (data.status > 200) {
            this.setState({
              showModalDelete: false,
              saving: false,
              showModalDeleteError: true,
              messageError: data.title == 'Unknown error',
            });
          } else {
            if (!this.props.createSite && this.redirectId != null) {
              document.getElementById('redirectToParent').click();
            } else if (window.location.href.includes('/installation')) {
              document.getElementById('redirect').click();
            } else if (window.location.href.includes('/site')) {
              document.getElementById('redirectsite').click();
            } else {
              this.props.onSaveClose();
            }
          }
        } else if (data.errors != undefined) {
          this.setState({
            showModalDelete: false,
            saving: false,
            showModalDeleteError: true,
            messageError: data.title == undefined ? t('installationspages.installationsummaryedit.forbid') : data.errors[0],
          });
        } else if (data.startsWith('System.InvalidOperation')) {
          // Forbid
          this.setState({
            showModalDelete: false,
            saving: false,
            showModalDeleteError: true,
            messageError: data.title == undefined ? t('installationspages.installationsummaryedit.forbid') : data.title,
          });
        } else {
          //ok
          if (window.location.href.includes('/installation')) {
            document.getElementById('redirect').click();
          } else if (window.location.href.includes('/site')) {
            document.getElementById('redirectsite').click();
          } else {
            this.props.onSaveClose();
          }
        }
      } else {
        this.setState({ showModalDelete: false, saving: false, showModalDeleteError: true, messageError: 'Name does not match' });
      }
    } catch (e) {
      if (e.response.status != undefined && e.response.status > 200) {
        this.setState({
          showModalDelete: false,
          saving: false,
          showModalDeleteError: true,
          messageError:
            e.response.data.title == undefined ? t('installationspages.installationsummaryedit.forbid') : e.response.data.title,
        });
      } else if (e.response.errors != undefined) {
        this.setState({
          showModalDelete: false,
          saving: false,
          showModalDeleteError: true,
          messageError:
            e.response.data.title == undefined ? t('installationspages.installationsummaryedit.forbid') : e.response.errors[0],
        });
      } else if (e.response.startsWith('System.InvalidOperation')) {
        // Forbid
        this.setState({
          showModalDelete: false,
          saving: false,
          showModalDeleteError: true,
          messageError:
            e.response.data.title == undefined ? t('installationspages.installationsummaryedit.forbid') : e.response.data.title,
        });
      }
    }

    return data;
  }

  async onMapclick(e) {
    if (this.type == 'site') {
      document.getElementById('txtLongitude').value = e.position[0];
      document.getElementById('txtLatitude').value = e.position[1];
      var direction = await this.searchDirectionsByLocation(e.position[0], e.position[1]);
      //this.setState({});
    }
  }

  async uploadFile(blnRefreshData, id) {
    this.http = new InstallationsHttpClient(this.context, id);
    const form = new FormData();
    form.append('file', this.state.imageFile);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    try {
      var data = await this.http.AvatarUploadFile(form, id).then((x) => {
        this.props.onSaveClose();
      });
    } catch (e) {
      this.props.onSaveClose();
    }
  }

  async confirmDelete(t) {
    //let element;
    //if (this.props.installation != undefined && this.props.installation.content != undefined && this.props.installation.content.length != undefined) {
    //    this.props.installation.content.map((item, i) => (
    //        this.buscarElementoInstalacion(item)
    //    ));
    //} else {
    //    this.elemento = this.props.installation != undefined ? this.props.installation : { children: null };
    //}

    //if (this.elemento.children != undefined && this.elemento.children.length > 0) {
    //    this.setState({ messageError: t('installationspages.installationsummaryedit.installationdelete'), title: t('installationspages.installationsummaryedit.deleteinstallationtitle'), showModalDeleteError: true });

    //}
    //else {
    await this.deleteInstallation(this.state.data.id, t);

    //}
  }

  buscarElementoInstalacion(ele) {
    if (ele.id == parseInt(this.id)) {
      this.elemento = ele;
      return ele;
    } else {
      ele.children.map((c) => this.buscarElementoInstalacion(c));
    }
  }

  async searchDirections(direction) {
    let data;

    this.http = new HttpClient(this.context);
    data = await this.http.searchDirection(direction);
    if (data.results.length > 0) {
    }
    return data;
  }
  async searchDirectionsByLocation(longitude, latitude) {
    let data;

    this.http = new HttpClient(this.context);

    data = await this.http.searchDirectionByLocation(longitude, latitude);
    if (data.addresses.length > 0) {
      try {
        document.getElementById('txtAddress').value = data.addresses[0].address.streetNameAndNumber;
        document.getElementById('txtLongitude').value = longitude;
        document.getElementById('txtLatitude').value = latitude;
        document.getElementById('txtPostalCode').value = data.addresses[0].address.postalCode;
        document.getElementById('txtCity').value = data.addresses[0].address.municipality;
        document.getElementById('txtCountry').value = data.addresses[0].address.country;
      } catch (err) {}
    }
    return data;
  }

  handleCloseConfirm() {
    this.setState({ openConfirmDelete: false });
  }

  async onKeyUp(e) {
    if (e.currentTarget.value.length > 15) {
    }
  }

  async getSiteLocation() {
    this.setState({ loadingSite: true });

    this.http = new InstallationsHttpClient(this.context, this.id);
    let response = await this.http.InstallationGetInstallationsById();

    if (response.status == 200) {
      let data = response.data;
      this.installation.address = data.content.address;
      this.installation.latitude = data.content.latitude;
      this.installation.longitude = data.content.longitude;
      this.installation.postalCode = data.content.postalCode;
      this.installation.city = data.content.city;
      this.installation.country = data.content.country;
    }
    this.setState({ loadingSite: false });
  }

  async deleteAvatar() {
    try {
      this.http = new InstallationsHttpClient(this.context, this.id);

      this.setState({ disableAll: true });

      await this.http.deleteAvatar(this.id);

      this.refresh = { id: 99 };
      this.setState({ openConfirmDelete: false, avatarImg: null, disableAll: false });
    } catch (err) {
      this.setState({ openConfirmDelete: false, disableAll: false });
    }
    }

    async getTimezones() {
        this.http = new HttpClient(this.context, this.id, null, null);
        let data = await this.http.GetTimezones();
        if (data.content) {
            this.setState({ _timezones: data.content });
        }
        else {
            this.setState({ _errors: data.errors });
        }
    }

    loadTimeZones() {
        if (this.state._timezones.length > 0) {
            return (
                this.state._timezones.map((elem, id) =>
                    <MenuItem key={elem.id} value={elem.id}>{elem.name}</MenuItem>
                )
            );
        } else {
            return (
                <div className='loadingParent'>
                    <div className='loadingImg'></div>
                </div>
            );
        }
    }

    handleTimeZoneChange = (e) => {
        this.setState({ selectedTimezone: e.target.value });
    }

  render() {
    const { t } = this.props;

    const handleChange = (event) => {
      switch (event.target.name) {
        case 'gender':
          this.genderValue = event.target.value;
          break;
        case 'area':
          this.areaValue = event.target.value;
          break;
        case 'environment':
          this.environmentValue = event.target.value;
          break;
        case 'type':
          this.typeValue = event.target.value;
          break;
        default:
          break;
      }

      this.installation[event.target.name] = event.target.value;
      this.setState({});
    };

    const btnImageChange = () => {
      document.getElementById('inputLayout').click();
    };

    const deleteInstallation = () => {
      var test = this.props;

      if (this.props.type == 'site') {
        this.setState({
          showModalDelete: true,
          title: t('actions.deletename', { name: t('site') }),
          question: t('actions.confirmdelete', { name: t('site') }),
        });
      } else {
        this.setState({
          showModalDelete: true,
          title: t('actions.deletename', { name: t('installation') }),
          question: t('actions.confirmdelete', { name: t('installation') }),
        });
      }
    };

    const handleCloseModal = () => {
      this.props.onSaveClose(this.refresh);
    };

    const handleImageChange = (e) => {
      e.preventDefault();

      let reader = new FileReader();
      let file = e.target.files[0];
      let fileSizeInMB = file.size / (1024 * 1024); // Size in mb
      if (fileSizeInMB > 2) {
        const fileSizeLimitMB = 2; // Size limit
        this.renderMessage('error', t('messages.errorSizeFile', { size: `${fileSizeLimitMB}mb` }));
      } else {
        reader.onloadend = () => {
          this.setState({
            imageFile: file,
            avatarImg: reader.result,
            status: 'change',
          });
        };
        reader.readAsDataURL(file);
      }
    };
    const handleCloseError = () => {
      this.setState({ showModalDeleteError: false, showModalDelete: false });
    };
    const handleClose = () => {
      this.setState({ showModalDelete: false });
    };

    const loadSiteLocation = (ev) => {
      this.getSiteLocation();
    };

    const maximizeMap = (ev) => {
      var mC = document.getElementById('mapContainer');
      var maxBtn = document.getElementById('maximizeBtn');
      var minBtn = document.getElementById('minimizeBtn');
      var tooltipMaxBtn = document.getElementById('button-tooltip');

      mC.setAttribute('class', 'form-group col-md-12');
      maxBtn.setAttribute('style', 'display: none; margin-left: auto; z-Index: 9');
      minBtn.setAttribute('style', 'display: block; margin-left: auto; z-Index: 9');

      if (tooltipMaxBtn !== null) {
        tooltipMaxBtn.setAttribute('style', 'display: none');
      }

      this.setState({ hMap: '64vh', wMap: '95%' });
    };

    const minimizeMap = (ev) => {
      var mC = document.getElementById('mapContainer');
      var maxBtn = document.getElementById('maximizeBtn');
      var minBtn = document.getElementById('minimizeBtn');
      var tooltipMinBtn = document.getElementById('button-tooltip');

      mC.setAttribute('class', 'form-group col-md-5 pr-4');
      maxBtn.setAttribute('style', 'display: block; margin-left: auto; z-Index: 9');
      minBtn.setAttribute('style', 'display: none; margin-left: auto; z-Index: 9');

      if (tooltipMinBtn !== null) {
        tooltipMinBtn.setAttribute('style', 'display: none');
      }

      this.setState({ hMap: '100%', wMap: '95%' });
    };

    const deleteImage = () => {
      this.setState({ openConfirmDelete: true });
    };

    let dataLoaded = this.state.data != undefined;
    let timezones = this.state._timezones;
    let showNoData = this.state.avatarImg && this.state.avatarImg != '' && this.state.avatarImg != ' ' ? false : true;

    let showInheritSiteLocation = this.props.type == 'installation' && this.props.mode == 'add';

    let startLocation = null;
    if (this.state.data?.longitude == 0) {
      if (this.context.brandCode == '11') startLocation = [7.5017362, 47.4148758];
      else if (this.context.brandCode == '14') startLocation = [2.1405686, 41.3911449];
    }

    const renderTooltip = (text) => (props) =>
      (
        <Tooltip
          id='button-tooltip'
          target='{target.current}'
          position='fixed'
          zIndex='999'
          style={{ zIndex: 999999999 }}
          {...props}
        >
          {text}
        </Tooltip>
      );

    return (
      <React.Fragment>
        {this.state.message && <Message {...this.state.message} onClose={this.handleDialogClose} />}
        {dataLoaded ? (
          <div>
            <Dialog disableEscapeKeyDown open={this.state.showModalDeleteError} onClose={handleCloseError}>
              <DialogTitle>{t('productspages.productedit.erroredittitle')}</DialogTitle>
              <DialogContent>
                <div>
                  <span>{this.state.messageError}</span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button color='primary' onClick={handleCloseError}>
                  {t('actions.close')}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog disableEscapeKeyDown open={this.state.showModalDelete} onClose={handleClose}>
              <DialogTitle>{this.state.title}</DialogTitle>
              <DialogContent>
                <div>
                  <span>{this.state.question}</span>
                  <br />
                  <span>{t('installationspages.installationsummaryedit.installationconfirmdelete')}</span>
                  <TextField
                    variant='standard'
                    id='txtNameDelete'
                    label={this.state.data.name}
                    placeholder={
                      this.type == 'installation'
                        ? t('installationspages.installationsummaryedit.nameinstallation')
                        : t('installationspages.installationsummaryedit.namesite')
                    }
                    helperText=''
                    fullWidth
                    margin='normal'
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button color='primary' onClick={() => this.confirmDelete(t)}>
                  {t('actions.yes')}
                </Button>
                <Button color='primary' onClick={handleClose}>
                  {t('actions.no')}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={this.state.openConfirmDelete}
              onClose={this.handleCloseConfirm}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
            >
              <DialogTitle id='alert-dialog-title'>{t('actions.delete')}</DialogTitle>
              <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                  {t('actions.confirmdelete', { name: t('general.thisImage') })}
                </DialogContentText>
                <DialogActions>
                  <Button className='primary pull-right' disabled={this.state.disableAll} onClick={this.handleCloseConfirm}>
                    {t('actions.no')}
                  </Button>
                  <Button className='primary pull-right' disabled={this.state.disableAll} onClick={this.deleteAvatar}>
                    {t('actions.yes')}
                  </Button>
                </DialogActions>
              </DialogContent>
            </Dialog>
            <form id='divForm' onSubmit={(e) => this.saveData(e, t)}>
              <div className='form-row prodChildNoZoom'>
                <div id='mapContainer' className='form-group col-md-5 pr-4'>
                  <div className='flexContainer'>
                    <OverlayTrigger
                      target='{target.current}'
                      trigger='hover, focus'
                      placement='top'
                      delay={{ show: 250, hide: 0 }}
                      overlay={renderTooltip(t('installationspages.installationsummaryedit.expandmap'))}
                    >
                      <button
                        id='maximizeBtn'
                        type='button'
                        className='tree float-right'
                        onClick={maximizeMap}
                        style={{ marginLeft: 'auto', zIndex: 9 }}
                      >
                        <img
                          width='28'
                          className='darkOnHover'
                          src={
                            'https://strgpiotcoredev.blob.core.windows.net/pictos-by-product/' +
                            this.context.brandCode +
                            '_maximize.svg'
                          }
                        />
                      </button>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 250, hide: 0 }}
                      target='{target.current}'
                      overlay={renderTooltip(t('installationspages.installationsummaryedit.collapsemap'))}
                    >
                      <button
                        id='minimizeBtn'
                        type='button'
                        className='tree float-right'
                        onClick={minimizeMap}
                        style={{ display: 'none', marginLeft: 'auto', zIndex: 9 }}
                      >
                        <img
                          width='28'
                          className='darkOnHover'
                          src={
                            'https://strgpiotcoredev.blob.core.windows.net/pictos-by-product/' +
                            this.context.brandCode +
                            '_minimize.svg'
                          }
                        />
                      </button>
                    </OverlayTrigger>
                  </div>

                  {!this.state.loadingSite ? (
                    <SinglePointMap
                      id='spMap'
                      height={this.state.hMap}
                      width={this.state.wMap}
                      startLocation={startLocation}
                      installation={this.state.data}
                      onMapClick={(e) => this.onMapclick(e)}
                    />
                  ) : null}
                  {showInheritSiteLocation ? (
                    <div className='flexContainer'>
                      <Button
                        style={{ marginLeft: 'auto', zIndex: 9 }}
                        variant='light'
                        className='managmentBtnClass'
                        onClick={loadSiteLocation}
                      >
                        <span className='spanRoca'>Copy site location</span>
                        <FileCopyOutlinedIcon style={{ height: '18px' }} />
                      </Button>
                    </div>
                  ) : null}
                </div>

                <div className='form-group col-md-7 form-row'>
                  <div className='form-group col-md-8'>
                    <TextField
                      onInvalid={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}
                      onInput={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}
                      onChange={(e) => e.target.setCustomValidity('')}
                      variant='standard'
                      id='txtName'
                      label={
                        this.type == 'installation'
                          ? t('installationspages.installationsummaryedit.nameinstallation')
                          : t('installationspages.installationsummaryedit.namesite')
                      }
                      placeholder={
                        this.type == 'installation'
                          ? t('installationspages.installationsummaryedit.nameinstallation')
                          : t('installationspages.installationsummaryedit.namesite')
                      }
                      helperText=''
                      fullWidth
                      required
                      margin='normal'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ maxLength: 255 }}
                      defaultValue={this.state.data.name}
                    />
                  </div>
                  <div className='form-group col-md-2'>
                    <TextField
                      variant='standard'
                      id='txtNumber'
                      label={t('installationspages.installationsummaryedit.number')}
                      placeholder={t('installationspages.installationsummaryedit.number')}
                      helperText=''
                      fullWidth
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3);
                        if (isNaN(e.target.value)) e.target.value = 0;
                      }}
                      margin='normal'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={this.state.data.number}
                    />
                  </div>
                  <div className='form-group col-md-2'>
                    <TextField
                      variant='standard'
                      id='txtLetter'
                      label={t('installationspages.installationsummaryedit.letter')}
                      inputProps={{ maxLength: 1 }}
                      placeholder={t('installationspages.installationsummaryedit.letter')}
                      helperText=''
                      fullWidth
                      margin='normal'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={this.state.data.letter}
                    />
                  </div>
                  <div className='form-group col-md-12'>
                    <TextField
                      variant='standard'
                      id='txtDescription'
                      label={t('forms.description')}
                      placeholder={t('forms.description')}
                      helperText=''
                      fullWidth
                      margin='normal'
                      inputProps={{ maxLength: 255 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={this.state.data.description}
                                    />
                                </div>
                                {
                                    this.type == 'site' &&

                                    <div className='form-group col-md-12'>
                                        <FormControl variant="standard" style={{width: '100%', paddingTop: '0.35em' }}>
                                            <InputLabel id='timezoneLabel'>{t('installationspages.installationsummaryedit.timezone')}</InputLabel>
                                            <Select
                                                variant="standard"
                                                labelId="demo-mutiple-title-label"
                                                id='timezone'
                                                name='timezone'
                                                required
                                                value={this.state.selectedTimezone}
                                                onChange={this.handleTimeZoneChange}
                                                disabled={this.type == 'installation' ? true : false}
                                                sx={{
                                                    '& .MuiSelect-select': {
                                                        whiteSpace: 'normal !important'
                                                    }
                                                }}
                                            >
                                                {this.loadTimeZones()}
                                            </Select>
                                        </FormControl>
                                    </div>
                                }
                  {this.type == 'installation' ? (
                    <div className='form-group col-md-3'>
                      <FormControl variant='standard' style={{ width: '100%' }}>
                        <InputLabel id='genderLabel'>{t('installationspages.installationsummaryedit.gender')}</InputLabel>
                        <Select
                          variant='standard'
                          name='gender'
                          className='titleProperty1'
                          labelId='genderLabel'
                          id='ddlGender'
                          value={this.installation.gender}
                          onChange={handleChange}
                        >
                          <MenuItem className='titleProperty1' value=''>
                            {t('installationspages.installationsummaryedit.selectgender')}
                          </MenuItem>
                          <MenuItem className='titleProperty1' value='ladies'>
                            {t('installationspages.installationsummaryedit.ladies')}
                          </MenuItem>
                          <MenuItem className='titleProperty1' value='gentlemen'>
                            {t('installationspages.installationsummaryedit.gentlemen')}
                          </MenuItem>
                          <MenuItem className='titleProperty1' value='neutral'>
                            {t('installationspages.installationsummaryedit.neutral')}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  ) : (
                    <div className='form-group col-md-3'> </div>
                  )}
                  {this.type == 'installation' ? (
                    <div className='form-group col-md-3'>
                      <FormControl variant='standard' style={{ width: '100%' }}>
                        <InputLabel id='areaLlabel'>{t('installationspages.installationsummaryedit.area')}</InputLabel>
                        <Select
                          variant='standard'
                          name='area'
                          className='titleProperty'
                          labelId='areaLlabel'
                          id='ddlArea'
                          label='Area'
                          value={this.installation.area}
                          onChange={handleChange}
                        >
                          <MenuItem value=''>
                            <em>{t('installationspages.installationsummaryedit.selectarea')}</em>
                          </MenuItem>
                          <MenuItem value='entrance'>{t('installationspages.installationsummaryedit.entrance')}</MenuItem>
                          <MenuItem value='staff'>{t('installationspages.installationsummaryedit.staff')}</MenuItem>
                          <MenuItem value='public'>{t('installationspages.installationsummaryedit.public')}</MenuItem>
                          <MenuItem value='other'>{t('installationspages.installationsummaryedit.other')}</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  ) : (
                    <div className='form-group col-md-3'> </div>
                  )}
                  {this.type == 'installation' ? (
                    <div className='form-group col-md-3'>
                      <FormControl variant='standard' style={{ width: '100%' }}>
                        <InputLabel id='environmentLabel'>
                          {t('installationspages.installationsummaryedit.environment')}
                        </InputLabel>
                        <Select
                          variant='standard'
                          name='environment'
                          className='titleProperty'
                          labelId='environmentLabel'
                          id='ddlEnvironment'
                          label='Environment'
                          value={this.installation.environment}
                          onChange={handleChange}
                        >
                          <MenuItem value=''>
                            <em>{t('installationspages.installationsummaryedit.selectenvironment')}</em>
                          </MenuItem>
                          <MenuItem value='hotel'>{t('installationspages.installationsummaryedit.hotel')}</MenuItem>
                          <MenuItem value='shopping'>{t('installationspages.installationsummaryedit.shopping')}</MenuItem>
                          <MenuItem value='infrastructure'>
                            {t('installationspages.installationsummaryedit.infrastructure')}
                          </MenuItem>
                          <MenuItem value='education'>{t('installationspages.installationsummaryedit.education')}</MenuItem>
                          <MenuItem value='business'>{t('installationspages.installationsummaryedit.business')}</MenuItem>
                          <MenuItem value='other'>{t('installationspages.installationsummaryedit.other')}</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  ) : (
                    <div className='form-group col-md-3'> </div>
                  )}
                  {this.type == 'installation' ? (
                    <div className='form-group col-md-3'>
                      <FormControl variant='standard' style={{ width: '100%' }}>
                        <InputLabel id='typeLabel'>{t('installationspages.installationsummaryedit.type')}</InputLabel>
                        <Select
                          variant='standard'
                          name='type'
                          className='titleProperty'
                          labelId='typeLabel'
                          id='ddlType'
                          label='Type'
                          value={this.installation.type}
                          onChange={handleChange}
                        >
                          <MenuItem value=''>
                            <em>{t('installationspages.installationsummaryedit.selecttype')}</em>
                          </MenuItem>
                          <MenuItem value='handicapped'>{t('installationspages.installationsummaryedit.handicapped')}</MenuItem>
                          <MenuItem value='service'>{t('installationspages.installationsummaryedit.service')}</MenuItem>
                          <MenuItem value='nursing'>{t('installationspages.installationsummaryedit.nursing')}</MenuItem>
                          <MenuItem value='shower'>{t('installationspages.installationsummaryedit.shower')}</MenuItem>
                          <MenuItem value='family'>{t('installationspages.installationsummaryedit.family')}</MenuItem>
                          <MenuItem value='other'>{t('installationspages.installationsummaryedit.other')}</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  ) : (
                    <div className='form-group col-md-3'> </div>
                  )}
                </div>

                <div className='form-group col-md-5 pr-4'>
                  {!this.state.loadingSite ? (
                    <div className='form-row'>
                      <div className='form-group col-md-12'>
                        <TextField
                          variant='standard'
                          id='txtAddress'
                          label={t('installationspages.installationsummaryedit.address')}
                          placeholder={t('installationspages.installationsummaryedit.address')}
                          helperText=''
                          fullWidth
                          margin='normal'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{ maxLength: 255 }}
                          defaultValue={this.state.data.address}
                          disabled={this.type == 'installation' ? true : false}
                        />
                      </div>
                      <div className='form-group col-md-6'>
                        <TextField
                          variant='standard'
                          id='txtLatitude'
                          label={t('installationspages.installationsummaryedit.latitude')}
                          placeholder={t('installationspages.installationsummaryedit.latitude')}
                          helperText=''
                          fullWidth
                          margin='normal'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          defaultValue={this.state.data.latitude}
                          disabled={this.type == 'installation' ? true : false}
                        />
                      </div>
                      <div className='form-group col-md-6'>
                        <TextField
                          variant='standard'
                          id='txtLongitude'
                          label={t('installationspages.installationsummaryedit.longitude')}
                          placeholder={t('installationspages.installationsummaryedit.longitude')}
                          helperText=''
                          fullWidth
                          margin='normal'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          defaultValue={this.state.data.longitude}
                          disabled={this.type == 'installation' ? true : false}
                        />
                      </div>
                      <div className='form-group col-md-4'>
                        <TextField
                          variant='standard'
                          id='txtPostalCode'
                          label={t('installationspages.installationsummaryedit.postalcode')}
                          placeholder={t('installationspages.installationsummaryedit.postalcode')}
                          helperText=''
                          fullWidth
                          inputProps={{ maxLength: 10 }}
                          margin='normal'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          defaultValue={this.state.data.postalCode}
                          disabled={this.type == 'installation' ? true : false}
                        />
                      </div>
                      <div className='form-group col-md-4'>
                        <TextField
                          variant='standard'
                          id='txtCity'
                          label={t('installationspages.installationsummaryedit.city')}
                          placeholder={t('installationspages.installationsummaryedit.city')}
                          helperText=''
                          fullWidth
                          margin='normal'
                          inputProps={{ maxLength: 255 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          defaultValue={this.state.data.city}
                          disabled={this.type == 'installation' ? true : false}
                        />
                      </div>
                      <div className='form-group col-md-4'>
                        <TextField
                          variant='standard'
                          id='txtCountry'
                          label={t('installationspages.installationsummaryedit.country')}
                          placeholder={t('installationspages.installationsummaryedit.country')}
                          helperText=''
                          fullWidth
                          margin='normal'
                          inputProps={{ maxLength: 255 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          defaultValue={this.state.data.country}
                          disabled={this.type == 'installation' ? true : false}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className='col-md-4 form-row form-group pr-4' style={{ display: 'grid' }}>
                  <div className='form-group'>
                    <TextField
                      variant='standard'
                      id='txtResponsableName'
                      label={t('installationspages.installationsummaryedit.responsablename')}
                      placeholder={t('installationspages.installationsummaryedit.responsablename')}
                      helperText=''
                      fullWidth
                      margin='normal'
                      inputProps={{ maxLength: 255 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={this.state.data.managerName}
                      disabled={this.type == 'installation' ? true : false}
                    />
                  </div>
                  <div className='form-group'>
                    <TextField
                      variant='standard'
                      id='txtResponsableEmail'
                      label={t('installationspages.installationsummaryedit.responsablemail')}
                      placeholder={t('installationspages.installationsummaryedit.responsablemail')}
                      helperText=''
                      fullWidth
                      margin='normal'
                      inputProps={{ maxLength: 255 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={this.state.data.managerMail}
                      disabled={this.type == 'installation' ? true : false}
                    />
                  </div>
                  <div className='form-group'>
                    <TextField
                      variant='standard'
                      id='txtResponsablePhone'
                      label={t('installationspages.installationsummaryedit.responsablephone')}
                      placeholder={t('installationspages.installationsummaryedit.responsablephone')}
                      helperText=''
                      fullWidth
                      margin='normal'
                      inputProps={{ maxLength: 50 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={this.state.data.managerPhone}
                      disabled={this.type == 'installation' ? true : false}
                    />
                  </div>
                </div>
                <div className='col-md-3 form-row form-group' style={{ display: 'grid' }}>
                  <div>
                    {showNoData ? (
                      <div className='card-container' style={{ width: '100%', height: '100px', paddingTop: '70px' }}>
                        <NoData />
                      </div>
                    ) : (
                      <canvas
                        className='card-container'
                        style={{
                          backgroundImage: "url('" + this.state.avatarImg + "')",
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center top',
                          width: '100%',
                          height: '100px',
                          backgroundSize: 'contain',
                        }}
                      ></canvas>
                    )}
                  </div>
                  <div className='row' style={{ marginRight: '5px' }}>
                    <div className='col-10'>
                      <Button
                        variant='light'
                        className='managmentBtnClass'
                        onClick={btnImageChange}
                        style={{ minWidth: '0px', padding: '0px' }}
                      >
                        <span className='spanRoca' style={{ padding: '0px' }}>
                          {t('installationspages.installationsummaryedit.uploadimage')}
                        </span>
                        <img style={{ marginLeft: '5px' }} src={require('../../../assets/icons/project-18.svg').default} />
                      </Button>
                    </div>
                    <div className='col-2'>
                      <Button
                        variant='light'
                        className='managmentBtnClass'
                        onClick={deleteImage}
                        disabled={!this.state.avatarImg || this.state.avatarImg == ''}
                      >
                            <DeleteOutlineIcon />
                      </Button>
                    </div>
                                </div>
                  <div className="row">
                      <div className='col-10'>
                                    <input type='file' id='inputLayout' style={{ display: 'none' }} onChange={handleImageChange} />
                                    <InputLabel
                                        variant='standard'
                                        id='inputchartcolorLabel'
                                        fullWidth
                                        margin='normal'
                                        style={{  lineHeight: '1' }}
                                        shrink="true">
                                        {t('installationspages.installationsummaryedit.installationcolor')}
                                        </InputLabel>
                                    <input
                                        id='inputchartcolor'
                                        name='inputchartcolor'
                                        type='color'
                                        style={{ marginBottom: '0.4rem' }}
                                        label={t('installationspages.installationsummaryedit.installationcolor')}
                                        value={this.state.chartcolor}
                                        onChange={(e) => this.setState({ chartcolor: e.target.value })}
                                    />
                      </div>
                  </div>
                </div>
              </div>
              <div className='form-row'>
                <div className='form-group col-md-12'>
                  {this.state.saving ? (
                    <div className='loadingParent'>
                      <div className='loadingImg'></div>
                    </div>
                  ) : null}
                </div>

                <div className='form-group col-md-6'>
                  <Button className='primary' onClick={handleCloseModal}>
                    {t('actions.close')}
                  </Button>
                </div>

                <div className='form-group col-md-6'>
                  <Button type='submit' className='primary active pull-right'>
                    {t('actions.save')}
                  </Button>
                  {this.props.mode == 'edit' && this.props.showdelete ? (
                    <Button className='primary pull-right' onClick={deleteInstallation}>
                      {t('actions.delete')}
                    </Button>
                  ) : (
                    <span></span>
                  )}
                </div>
              </div>
            </form>
            <Link id='redirect' to='/installations' style={{ display: 'none' }}></Link>
            <Link id='redirectToParent' to={'/site/' + this.redirectId} style={{ display: 'none' }}></Link>
            <Link id='redirectsite' to='/sites' style={{ display: 'none' }}></Link>
          </div>
        ) : (
          <div className='loadingParent'>
            <div className='loadingImg'></div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
export default withTranslation()(InstallationsSummaryEdit);
