import { SelectChangeEvent } from '@mui/material';
import type { CustomSelectContainer } from '../container/CustomSelectContainer';
import { Disabled, Parameter } from '../types/CustomSliderTypes';

class CustomSelectConfig {
  private customSelectContainer: CustomSelectContainer;

  constructor(customSelectContainer: CustomSelectContainer) {
    this.customSelectContainer = customSelectContainer;
    this.handleDisabledClick = this.handleDisabledClick.bind(this)
    this.isDisabled = this.isDisabled.bind(this)
  }

  handleChange = (event: SelectChangeEvent<string>) => {
    var data = this.customSelectContainer.state.data;

    if(data == undefined) { return }
    data.value = event.target.value.toString()

    this.onChangeSelect(event.target.value, this.customSelectContainer.props.parameter.id);
    this.toSend(this.customSelectContainer.props.parameter, event.target.value);
    
    //Call parent onchange
    if (typeof this.customSelectContainer.props.onChange === 'function') {
            this.onChange('select');
    }

    this.customSelectContainer.setState({ data });

  };

  handleDisabledClick(disabled: Disabled) {
    let {data} = this.customSelectContainer.state;
    if (this.customSelectContainer.props.handleDisabledMessage && data && this.isDisabled()) {
      this.handleDisabledMessage(disabled);
    }
  }

  onChange(type:string) {
    this.customSelectContainer.props.onChange(type)
  }

  toSend(parameter: Parameter, value: string){
    this.customSelectContainer.props.toSend(parameter, value)
  }

  onChangeSelect(value:string, id:string){
    this.customSelectContainer.props.onChangeSelect(value, id)
  }

  handleDisabledMessage(disabled: Disabled){
    this.customSelectContainer.props.handleDisabledMessage!(disabled)
  }

  isDisabled(){
    let {data} = this.customSelectContainer.state;
    return !data!.online || data!.permissionType == 'R';
  }
}

export default CustomSelectConfig;