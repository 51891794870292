import React, { Component } from 'react';
import '../../../../App.css';
import ProductsTechnicalInformationComponents from './ProductsTechnicalInformationComponents';
import ProductsTechnicalInformationTechInfo from './ProductsTechnicalInformationTechInfo';
import ProductsThermalDisinfection from './ProductsThermalDisinfection';
import { withTranslation } from 'react-i18next';
import AuthenticationContext from "../../../../Authentication/types/AuthContextType";


class ProductsTechnicalInformation extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        this.state = { _data: [], hasInfo: false };
        if (window.location.pathname.split('/').length > 0)
            this.id = parseInt(window.location.pathname.split('/')[2]);
        this.dataContent = this.props.data;        
        this.mixDataContent = null; 
    }

    onChangeDate(value, groupby) {
        if (typeof this.props.onChangeDate === 'function') {
            this.props.onChangeDate(value, groupby);
        }
    }

    hasInfo = (status) => {
        if(status !== this.state.hasInfo){
            this.setState({ hasInfo: status });
        }
    }
    
    render() {
        const { t } = this.props;
        let dataLoaded = this.props.product.content != undefined;       
        if (dataLoaded) {            
            if (!this.mixDataContent) {
                this.mixDataContent = this.dataContent;
            }
        }

        return (

            <React.Fragment>
                {this.mixDataContent != null ?
                    <React.Fragment>
                        <div>
                            <h2 className="mb-4" style={{ marginLeft: "4px" }} >{t("productspages.technical.techDocuments")} </h2>
                        </div>

                        <ProductsTechnicalInformationTechInfo hasInfo={this.hasInfo} data={this.mixDataContent} product={this.props.product} BIMS={true} resources2D3D={true} drawings={true} installation={true} />

                    </React.Fragment>
                    : null

                }
                  
                <div>
                    {dataLoaded && this.props.product?.content?.smartComponents?.length > 0 ? (
                        <div style={{ margin: "20px 0px" }}>
                            <h2 className="mb-4 pt-3" style={{ marginLeft: "4px" }} >{t("productspages.technical.components")} </h2>
                            <ProductsTechnicalInformationComponents hasInfo={this.hasInfo} product={this.props.product} /></div>
                    ) : (
                        null
                    )
                    }
                    {dataLoaded ? (
                        <ProductsThermalDisinfection hasInfo={this.hasInfo} product={this.props.product} groupBySelected={this.props.groupBySelected} dateRange={this.props.dateRange} onChangeDate={this.onChangeDate.bind(this)} />
                    ) : (
                        null
                    )

                    }
                </div>

                {!this.mixDataContent && !dataLoaded && !(dataLoaded && this.props.product?.content?.smartComponents?.length > 0) || !this.state.hasInfo  ? <div class="kpiValue t3 text-left mb-0">{t('nodata')}</div> : "" }
            </React.Fragment>
           
        );
    }

}
export default withTranslation()(ProductsTechnicalInformation)