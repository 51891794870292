import InstallationsTree from "../../InstallationsTree";
import InstallationsGeneralMainChartsContainer from "../../General/InstallationsGeneralMainCharts/container/InstallationsGeneralMainChartsContainer";
import { InstallationsGeneralViewProps } from "../types/InstallationsGeneralTypes";
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InstallationsLayout from "../../InstallationsLayout";
import { t } from 'i18next';

const InstallationsGeneralView = (props: InstallationsGeneralViewProps) => {
    return (
        <div className="row">
                
                <div className={" col-12 col-xl-6 pr-xl-0 pl-xl-3 mb-5 " /*+ (this.props.data?.floorPlanUri != undefined ? "" : "d-none")*/} style={{ height: "fit-content" }}>
                    <div className={"prodChildNoZoom " + (props.showLayout? "": "collapsed")  } id="InstallationLayoutParent" style={{ margin: 0, padding: "20px", paddingTop:0 }}>
                        <div style={{ overflowX: "auto" }}>
                            <div className={"row " + (props.showLayout ? "" : "collapsed2")} style={{ margin: '10px 20px', marginTop:"2px", marginRight:0 }}>
                                <h3 className="px-0 col" >{t('installationspages.layout')}</h3>
                                <IconButton
                                    className="toggleLayoutBtn px-0"
                                    onClick={(e) => props.toggleLayout()}
                                    aria-label="close"
                                    size="large">
                                    {
                                        props.showLayout ? <ExpandMoreIcon /> : <ChevronRightIcon />
                                    }
                                </IconButton>
                            </div>
                            {
                                props.showLayout ? <InstallationsLayout editMode={true} data={props.data} onChange={props.componentChanged.bind(this) } /> : null
                            }
                        </div>
                    </div>
                </div>
                <div className="col order-xl-first" style={{overflowX:"auto", overflowY:"hidden"}}>
                    <InstallationsTree selectedKey={props.expanded} notifications={props.notifications} products={props.data.products}></InstallationsTree>
                </div>
                
               
                
                <div style={{ margin: "20px 0px" }}><InstallationsGeneralMainChartsContainer product={props.data} groupBy={props.groupBy} chartViewZones={props.chartViewZones} /></div>
            </div>
    );
  };
  
  export default InstallationsGeneralView;