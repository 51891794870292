import React, { Component } from 'react';
import '../../../App.css';
import '../../ProductsStyle.css';
import SiteDetail from './SiteDetail';
import InstallationsReporting from '../../InstallationComponents/Tabs/InstallationsReporting';
import InstallationsAlertReportingContainer from '../../InstallationComponents/Tabs/InstallationsAlertReporting/container/InstallationsAlertReportingContainer';
import AlertHttpClient from "../../../HttpClient/AlertHttpClient";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/material/styles';
import AuthenticationContext from "../../../Authentication/types/AuthContextType";
import { withTranslation } from 'react-i18next';
import { withRouter } from '../../withRouter';
import InstallationUsers from '../../InstallationComponents/Tabs/InstallationUsers';

function SetClasses() {
    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: theme.palette.background.paper,
            width: 500,
        },
    }));
    return useStyles();
}

function SetStyles() {
    const classes = SetClasses();
    return classes;
}

function SetTheme() {
    const theme = useTheme();
    return theme;
}

class SiteTabs extends Component {
    static contextType = AuthenticationContext;

    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0, tabIndex: 0, show: false, activeClass: '', dateRange: null, notificationAlerts: null

        }
        if (this.props.match != undefined && this.props.match.params != undefined && this.props.match.params.id != undefined && this.props.location.pathname.split('/').length > 1)
            this.id = this.props.match.params.id == undefined ? parseInt(this.props.location.pathname.split('/')[2]) : this.props.match.params.id;
        else {
            if (window.location.pathname.split('/').length > 0)
                this.id = parseInt(window.location.pathname.split('/')[2]);
        }
    }
    componentDidMount() {
        this.getNotificationAlerts();
    }


    handleChange = (event, newValue) => {

        // This are the id's of the tabs we want to show the granularity selector on
        let show = false;//(newValue == 1 || newValue == 2 || newValue == 3)
        this.props.showSelector(show)
        this.setState({ tabValue: newValue });

        let elem = document.getElementById("parentElementIsTop");
        if (newValue == 0 && elem != null) {
            elem.classList.remove("forceVisble");
        } else if (elem != null) {
            elem.classList.add("forceVisble");
        }
    };

    handleChangeIndex = (index) => {
        this.setState({ tabIndex: index });
    };

    async getNotificationAlerts() {
        let notifications = [];


        this.httpAlerts = new AlertHttpClient(this.context, this.id, null, null, null, 0, 10);
        let data = await this.httpAlerts.GetProducedIndividualRulesTotalsBySite();
        let dataList = await this.httpAlerts.GetProducedIndividualRulesBySite({ all: true });
        if (data.content.length > 0) {
            let list = null;
            if (dataList.content.length > 0) {
                list = dataList.content;
            }
            notifications.push({ alerts: data.content, alertslist: list });
        }


        this.setState({ notificationAlerts: notifications });
    }
    componentDidUpdate(prevProps) {
        let siteId = parseInt(window.location.pathname.split('/')[2]);
        if (this.id != siteId) {
            this.id = siteId;
            this.getNotificationAlerts();
        }
    }
    handleClick() {
        if (typeof this.props.handleClick === 'function') {
            this.props.handleClick();
        }
    }
    onChangeDate(value, groupby) {
        if (typeof this.props.onChangeDate === 'function') {
            this.props.onChangeDate(value, groupby);
        }
    }

    render() {
        const { t } = this.props;



        function TabPanel(props) {
            const { children, value, index, ...other } = props;

            return (
                <div
                    role="tabpanel"
                    hidden={value !== index}
                    id={`full-width-tabpanel-${index}`}
                    aria-labelledby={`full-width-tab-${index}`}
                    {...other}
                >
                    {value === index && (
                        <Box p={3}>
                            <Typography component={'span'}>{children}</Typography>
                        </Box>
                    )}
                </div>
            );
        }

        TabPanel.propTypes = {
            children: PropTypes.node,
            index: PropTypes.any.isRequired,
            value: PropTypes.any.isRequired,
        };

        function a11yProps(index) {
            return {
                id: `full-width-tab-${index}`,
                'aria-controls': `full-width-tabpanel-${index}`,
            };
        }
        const onRefresh = () => {
            if (typeof this.props.onRefresh === 'function') {
                this.props.onRefresh();
            }
        }
        let dataLoaded = this.state.notificationAlerts != null;

        return (
            <React.Fragment>
                <div className="" style={{ marginTop: '-10px' }}>

                    <AppBar position="static" color="default" className={"headerStickyCustom " + this.state.activeClass}>
                        <Tabs
                            value={this.state.tabValue}
                            onChange={this.handleChange}
                            indicatorColor='primary'
                            variant="fullWidth"
                            className="container"
                        >
                            <Tab label={<h3 className="tabTitle">{t('productspages.general.tabname')}</h3>} {...a11yProps(0)} />
                            <Tab label={<h3 className="tabTitle">{t('productspages.reporting.tabname')}</h3>} {...a11yProps(1)} />
                            <Tab label={<h3 className="tabTitle">{t('productspages.alertreporting.tabname')}</h3>} {...a11yProps(2)} />
                            <Tab label={<h3 className="tabTitle">{t('users')}</h3>} {...a11yProps(3)} />
                        </Tabs>
                    </AppBar>
                    <SwipeableViews
                        axis={SetTheme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={this.state.tabValue}
                        onChangeIndex={this.handleChangeIndex}
                        className="container siteTabs-swipeable"
                    >
                        <TabPanel value={this.state.tabValue} index={0} dir={SetTheme.direction}>
                            <div style={{ margin: "10px 0px", marginTop: "-15px", position: "relative" }}>
                                {dataLoaded ?
                                    <SiteDetail data={this.props.data} refresh={this.props.refreshAllComponents} onRefresh={onRefresh} buttonClick={this.handleClick.bind(this)} notifications={this.state.notificationAlerts} />
                                    : <React.Fragment></React.Fragment>
                                }

                            </div>
                        </TabPanel>
                        <TabPanel value={this.state.tabValue} index={1} dir={SetTheme.direction}>
                            <InstallationsReporting data={this.props.data} dateRange={this.props.dateRange} groupBySelected={this.props.groupBySelected} chartViewZones={this.props.chartViewZones} onChangeDate={this.onChangeDate.bind(this)} />
                        </TabPanel>
                        <TabPanel value={this.state.tabValue} index={2} dir={SetTheme.direction}>
                            <InstallationsAlertReportingContainer installation={this.props.data} />
                        </TabPanel>
                        <TabPanel value={this.state.tabValue} index={3} dir={SetTheme.direction}>
                            <InstallationUsers data={this.props.data} />
                        </TabPanel>
                    </SwipeableViews>
                </div>
            </React.Fragment>
        );
    }
}
export default withTranslation()(withRouter(SiteTabs, [{ path: '/site/:id' }]));


