
import React, { Component } from 'react';
import '../../../App.css';
import '../../ProductsStyle.css';
import { withTranslation } from 'react-i18next';
import SinglePointMap from '../../SinglePointMap';
import AuthenticationContext from "../../../Authentication/types/AuthContextType";
import Moment from 'react-moment';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from '@mui/material/Button';

class InstallationsSummary extends Component {
    static contextType = AuthenticationContext;

    constructor(props) {
        super(props);
        this.laufen = false;
        this.id = parseInt(window.location.pathname.split('/')[2]);

        this.state = {
            installation: this.props.installation, notificationList: null
        };

        this.key = 0;

    }

    componentDidUpdate(prevProps) {
        
        let idInstallation = parseInt(window.location.pathname.split('/')[2]);
        
        if (idInstallation != this.id || this.props.refresh) {
            this.localRefresh = true;
            this.id = idInstallation;
            this.props.onRefresh();
            //this.getNotifications();
        }
    }
    handleClick = () => {
        if (typeof this.props.buttonClick === 'function') {
            this.props.buttonClick();
        }
    };
    getNotifications() {
        const { t } = this.props;
        const types = ['alarm', 'warning', 'rule'];
        const names = { "rule": t("productspages.alertreporting.rules"), "alarm": t("productspages.alertreporting.alarms"), "warning": t("productspages.alertreporting.warnings") };
        const name = { "rule": t("productspages.alertreporting.rule"), "alarm": t("productspages.alertreporting.alarm"), "warning": t("productspages.alertreporting.warning") };

        if (this.props.notifications == undefined || this.props.notifications == null) {
            return null;
        } else if (this.props.notifications.length <= 0) {
            return null;
        }
        let notifications = this.props.notifications[0].alerts;

        let arr = [];
        types.forEach(element => {
            let data = notifications.find(o => o.type === element);
            if (data == null) {
                return;
            }
            let total = data.total != null ? data.total : 0;

            if (total <= 0) { return }

            this.key++;

            arr.push(
                <div key={this.key} className="col-auto pl-0 pr-1">
                    <span className={"custom-button-span " + element} style={{ whiteSpace: "nowrap" }}>
                        {total + " " + (total > 1 ? names[element] : name[element])}
                    </span>
                </div>);

        });

        let ret = <div className="row">
            <div className="col-12 ">
                <div className="row m-0 listNotificationsItems">
                    {arr}
                </div>
                
            </div>
        </div>;

        return ret;
    }
    getStatusList(type, total, text) {
        let t = type.toLowerCase();
        if (total <= 0) {
            return;
        }
        return (
            <div className="col-12">
                <div className="row">
                    <div className="col-12 d-flex" style={{ alignItems: "center" }} >
                        <img src={require('../../../assets/icons/dot.svg').default} className={"iconStatusList bullet-" + t} height="10" width="10" />
                        <span className="pl-2 t2 bold m-0">{total + " " + text}</span>
                    </div>

                </div>
            </div>

        );
    }
    render() {
        const { t } = this.props;

        const renderTooltip = (text) => (props) => (
            <Tooltip id="button-tooltip" {...props}>
                {text}
            </Tooltip>
        );

        this.laufen = this.context.brandCode == 11;

        let dataLoaded = this.props.installation != null;
        
        let imageHeader = "";

        let contadorProductos = 0;
        if (dataLoaded) {

            contadorProductos = (this.props.installation.activatedCount == null ? 0 : this.props.installation.activatedCount) + (this.props.installation.desactivatedCount == null ? 0 : this.props.installation.desactivatedCount) + (this.props.installation.errorCount == null ? 0 : this.props.installation.errorCount);
           
            if (this.props.installation.avatarUri != null) {
                imageHeader = this.props.installation.avatarUri;
            }
            else {
                imageHeader = this.laufen ? "https://strgpiotcoredev.blob.core.windows.net/pictos-by-kpi/11_LAUFEN_Default_Installation.jpeg" : "https://strgpiotcoredev.blob.core.windows.net/pictos-by-kpi/14_ROCA_Default_Installation.jpeg";
            }
        
        }
        let aggregatedInfo = {}
        if (this.props.installation) aggregatedInfo = this.props.installation.aggregatedInfo ? this.props.installation.aggregatedInfo : {}

        let map = null;

        if (this.props.installation && this.props.installation.longitude) {
            map = <SinglePointMap installation={this.props.installation} />
        }

        let address = ""

        if (this.props.installation) {
            if (this.props.installation.address && this.props.installation.address != "") {
                address += this.props.installation.address
            }
            if (this.props.installation.postalCode && this.props.installation.postalCode != "") {
                if (address.length > 1) address += " - "
                address += this.props.installation.postalCode
            }
            if (this.props.installation.city && this.props.installation.city != "") {
                if (address.length > 1) address += ", "
                address += this.props.installation.city
            }
            if (this.props.installation.country && this.props.installation.country != "") {
                if (address.length > 1) address += ", "
                address += this.props.installation.country
            }
        }

        let appProducts = 0;
        let disconnectedProducts = 0;
        let offlineProducts = 0;
        let onlineProducts = 0;
        if (aggregatedInfo) {
            if (aggregatedInfo["deviceStatus"] !== undefined) {
                if (aggregatedInfo["deviceStatus"]["App"] !== undefined) appProducts = aggregatedInfo["deviceStatus"]["App"];
                if (aggregatedInfo["deviceStatus"]["Offline"] !== undefined) disconnectedProducts += aggregatedInfo["deviceStatus"]["Offline"];
                if (aggregatedInfo["deviceStatus"]["Disconnected"] !== undefined) disconnectedProducts += aggregatedInfo["deviceStatus"]["Disconnected"];
                if (aggregatedInfo["deviceStatus"]["Online"] !== undefined) onlineProducts = aggregatedInfo["deviceStatus"]["Online"];
            }
        }
        let boton = null;
        if (this.props.installation.hasOwnerAccess) {
            boton = <div className="edit-parent" ><Button className="primary float-right w-sm-100" onClick={() => this.handleClick()}> <img src={require('../../../assets/icons/edit-18.svg').default} /></Button></div>
        }

        return (

            <React.Fragment>
                
                {dataLoaded ? (
                    <div>
                        <div className="row prodInfo" >
                            <div className="col-12">
                                <div className="row prodInfo" style={{ alignItems: "stretch", paddingBottom: "10px" }}>
                                    <div className="col-12 col-md-3 pr-md-3" style={{padding:'0'}}>
                                        <div  style={{
                                            backgroundImage: "url('" + imageHeader +"')",
                                            backgroundSize: "contain",
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "center",
                                            minHeight: "200px",
                                            height: '100%',
                                            width: '100%'
                                        }}
                                            className="customImageSize"
                                        >
                                    </div>   
                                       
                                    </div>
                                    <div className="col-12 col-md-9  mt-3 mt-md-0 pt-2">
                                        <div className="row mb-2">
                                            <h2 className="mb-0 col">{this.props.installation.name}</h2>
                                            {boton}
                                        </div>
                                        <div className="row summaryInfo " style={{ height: "100%" }}>
                                            
                                            <div className="col-12 col-md-4 " style={{ position: "relative", alignSelf: "top" }}>
                                                {this.getNotifications()}
                                                <div className="row pt-2" style={{}}>
                                                   
                                                    {this.getStatusList("Online", onlineProducts, t('tooltips.activeProducts'))}
                                                    {this.getStatusList("App", appProducts, t('tooltips.appProducts'))}
                                                    {this.getStatusList("Disconnected", disconnectedProducts, t('tooltips.unactiveProducts'))}
                                                    
                                                </div>
                                               
                                                <div className="row pt-3">
                                                    <div className="col-12">
                                                        <div className="row m-0">
                                                        
                                                            <div className="col pl-0 pr-1" style={{ padding: 0 }}>
                                                                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t('actions.devices'))}>
                                                                    <div className="iconParent">
                                                                        <img src={require('../../../assets/icons/11_00.03_black.svg').default} />
                                                                        <div className="t3" style={{ float: 'left', marginBottom: 0 }}> {aggregatedInfo.totalDevices} </div>
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <div className="col pl-0 pr-1" style={{ padding: 0 }}>
                                                                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t('users'))}>
                                                                    <div className="iconParent">
                                                                        <img src={require('../../../assets/icons/PersonOutlineIcon.svg').default} />

                                                                        <div className="t3" style={{ float: 'left', marginBottom: 0 }}> {aggregatedInfo.totalUsers} </div>
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <div className="col pl-0 pr-1" style={{ padding: 0 }}>
                                                                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t('gateways'))}>
                                                                    <div className="iconParent">
                                                                        <img src={require('../../../assets/icons/11_00.31_black.svg').default} />
                                                                        <div className="t3" style={{ float: 'left', marginBottom: 0 }}> {aggregatedInfo.gatewayDevices} </div>
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <div className="col pl-0 pr-1" style={{ padding: 0 }}>
                                                                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t('installations'))}>
                                                                    <div className="iconParent">
                                                                        <img src={require('../../../assets/icons/BusinessIcon.svg').default} />
                                                                        <div className="t3" style={{ float: 'left', marginBottom: 0 }}> {aggregatedInfo.totalInstallations} </div>
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </div>
                                                         </div>
                                                    </div>
                                                </div>
                                                <h6 className="pt-3 d-none">IOT DATA:</h6>
                                                <div className="t2 d-none"> {this.props.installation.name}</div>
                                                <div className="t2 d-none"> <b>{t('installationspages.installationsummary.contractnumber')}: </b>--------</div>
                                                
                                            </div>
                                          
                                            <div className="col-12 col-md-5 pt-3 pt-md-0 spacebar" style={{ alignSelf: "top" }}>
                                               
                                                <h6 className="pb-2"> {t('installationspages.installationsummary.address')}: </h6>
                                                <div className="t2"> {address}  </div>
                                                <div className="t2"> <b>{t('installationspages.installationsummary.registered')}:</b> <Moment format="D MMM YYYY HH:mm" withTitle locale="es">{this.props.installation.createdAt}</Moment> </div>

                                               
                                            </div>
                                            <div className="col-12 col-md-3 pt-3 pt-md-0 spacebar"  style={{ alignSelf: "top"  }}>
                                                <h6 className="pb-2">{t('installationspages.installationsummary.contact')}:</h6>
                                                <div className="t2">{this.props.installation.managerMail}</div>
                                                <div className="t2">{this.props.installation.managerPhone}</div>
                                                <div className="t2">{this.props.installation.managerName}</div>
                                                <div className="row d-none">
                                                    <div className="col-12">
                                                        { map }
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                        <div className="loadingParent"><div className="loadingImg"></div></div>
                    )}
            </React.Fragment>
        );
        
    }

}
export default withTranslation()(InstallationsSummary);