import { PureComponent } from 'react';
import ModalAddPackageView from '../views/ModalAddPackageView';
import AuthenticationContext from '../../../../../../../Authentication/types/AuthContextType';
import {
  ModalAddPackageContainerProps,
  ModalAddPackageContainerState,
  OptionsSmartComponentTypes,
  SelectNumberOption,
  SelectStringOption,
} from '../types/ModalAddPackageTypes';
import NoSmartComponentsHttpClient from '../../../../../../../HttpClient/NoSmartComponentsHttpClient';
import { NewHardwareFromNewPackage } from '../../HardwareManagement/types/ModalAddHardwareTypes';

class ModalAddPackageContainer extends PureComponent<ModalAddPackageContainerProps, ModalAddPackageContainerState> {
  static contextType = AuthenticationContext;

  constructor(props: ModalAddPackageContainerProps) {
    super(props);
    this.state = {
      step: 0,
      totalSteps: 3,
      typePackage: 'systemId',
      name: null,
      description: null,
      version: null,
      systemId: null,
      available: false,
      production: false,
      allNoSmartComponents: this.props.allNoSmartComponents,
      optionsSmartComponentTypes: [],
      optionsNoSmartCompoBySystemId: [],
      optionsSmartCompoTypesBySystemId: [],
      selectedNoSmartCompoBySystemId: null,
      selectedSmartCompoTypesBySystemId: null,
      showAddHWModal: false,
      newHardwareFromNewPackage: undefined,
      isNextStep2Disabled: true,
      isNextStep1Disabled: true,
    };

    this.handleStep.bind(this);
    this.savePackage.bind(this);
    this.handleValueTextField.bind(this);
    this.handleSystemId.bind(this);
    this.handleCheck.bind(this);
    this.handle2Selects.bind(this);
    this.handleShowModalFromPackageWithSmartCompo.bind(this);
    this.handleShowModalFromPackageWithNoSmartCompo.bind(this);
  }

  componentDidUpdate() {
    this.checkIfNextStep1Disabled();
  }

  checkIfNextStep1Disabled = () => {
    const { name, systemId, version } = this.state;
    const versionRegex = /^[0-9]{2}\.[0-9]{2}$/;
    const isVersionOK = version && versionRegex.test(version);
    this.setState({ isNextStep1Disabled: !(name && systemId && version && isVersionOK) });
  };

  handleStep = (step: 'prev' | 'next') => {
    if (step === 'prev') {
      this.setState({ step: this.state.step - 1 });
      // this.cleanFields();
    } else if (step === 'next') {
      this.setState({ step: this.state.step + 1 });
    }
  };

  savePackage = async () => {
    try {
      const httpNoSmartCompo = new NoSmartComponentsHttpClient(this.context);
      const noSmartComponentPackage = {
        version: this.state.version,
        name: this.state.name,
        description: this.state.description || '',
        available: this.state.available,
        production: this.state.production,
        systemId: this.state.systemId,
      };

      const response = await httpNoSmartCompo.createNoSmartComponentPackage(noSmartComponentPackage);

      if (response && response.content && response.content.id) {
        const noSmartComponentPackageId = String(response.content.id);
        const selectedSmartsCompos = this.state.selectedSmartCompoTypesBySystemId || {};

        // Añadir dependencias para smart components secuencialmente
        for (const selectedKey of Object.keys(selectedSmartsCompos)) {
          const smartCompoId = String(selectedSmartsCompos[selectedKey].value);
          await httpNoSmartCompo.addDependencyToPackage(noSmartComponentPackageId, smartCompoId);
        }

        // Añadir dependencia para no smart component seleccionado si existe
        if (this.state.selectedNoSmartCompoBySystemId) {
          await httpNoSmartCompo.addDependencyToPackage(
            noSmartComponentPackageId,
            this.state.selectedNoSmartCompoBySystemId.value
          );
        }

        this.props.handleShowAddPackageModal(false, true);
      }
    } catch (error) {
      console.error('Error saving package:', error);
    }
  };

  handleValueTextField = (value: string, nameTextField: 'name' | 'description' | 'version') => {
    this.checkIfNextStep1Disabled();
    this.setState({ [nameTextField]: value } as Pick<ModalAddPackageContainerState, 'name' | 'description' | 'version'>);
  };

  handleSystemId = (option: SelectStringOption) => {
    const systemIdSelected = this.props.systemIds.filter((systemId) => systemId.id === option.value)[0];
    const optionsSmartCompoTypesBySystemId = systemIdSelected.smartComponentTypes;
    const optionsSmartComponentTypes: OptionsSmartComponentTypes = {};
    optionsSmartCompoTypesBySystemId.forEach((smartComponentType) => {
      const noSmartsRelated = this.state.allNoSmartComponents.filter(
        (NoSmartCompo) => NoSmartCompo.smartComponentType && NoSmartCompo.smartComponentType.id === smartComponentType.id
      );
      optionsSmartComponentTypes[smartComponentType.id] = noSmartsRelated;
    });

    const optionsNoSmartCompoBySystemId = this.state.allNoSmartComponents.filter(
      (NoSmartCompo) => NoSmartCompo.noSmartComponentType && NoSmartCompo.noSmartComponentType.systemId === systemIdSelected.id
    );
    this.checkIfNextStep1Disabled();
    this.setState({
      systemId: option.value,
      optionsSmartComponentTypes,
      optionsSmartCompoTypesBySystemId,
      optionsNoSmartCompoBySystemId,
      selectedNoSmartCompoBySystemId: null,
      selectedSmartCompoTypesBySystemId: null,
    });
  };

  handleCheck = (value: boolean, nameCheckField: 'available' | 'production') => {
    this.setState({ [nameCheckField]: value } as Pick<ModalAddPackageContainerState, 'available' | 'production'>);
  };

  handle2Selects = (
    option: SelectNumberOption,
    name: 'selectedSmartCompoTypesBySystemId' | 'selectedNoSmartCompoBySystemId',
    id?: number
  ) => {
    this.setState((prevState) => {
      const {
        isNextStep2Disabled: currentIsNextStep2Disabled,
        selectedSmartCompoTypesBySystemId,
        optionsSmartComponentTypes,
      } = prevState;

      let isNextStep2Disabled = currentIsNextStep2Disabled;

      if (name === 'selectedSmartCompoTypesBySystemId') {
        const allSelected = selectedSmartCompoTypesBySystemId ? { ...selectedSmartCompoTypesBySystemId } : {};
        allSelected[id!] = option;
        isNextStep2Disabled = !(Object.keys(allSelected).length === Object.keys(optionsSmartComponentTypes).length);
      }

      return {
        ...prevState,
        isNextStep2Disabled,
        [name]:
          id !== undefined
            ? {
                ...prevState[name],
                [id]: option,
              }
            : option,
      };
    });
  };

  handleShowModalFromPackageWithSmartCompo = async (status: boolean, smartCompoTypeId?: number, newHardwareSaved?: boolean) => {
    const newHardwareFromNewPackage: NewHardwareFromNewPackage = {
      smartCompoTypeId: smartCompoTypeId ? smartCompoTypeId : null,
      systemId: this.state.systemId!,
      selectedType: 'systemId',
    };

    let allNoSmartComponents = this.state.allNoSmartComponents;
    let optionsSmartComponentTypes = this.state.optionsSmartComponentTypes;

    // use case after saved new hardware (smartCompo)
    if (
      !status &&
      newHardwareSaved &&
      this.state.newHardwareFromNewPackage &&
      this.state.newHardwareFromNewPackage.smartCompoTypeId
    ) {
      allNoSmartComponents = await this.getNoSmartComponents();
      this.state.optionsSmartCompoTypesBySystemId.forEach((smartComponentType) => {
        const noSmartsRelated = allNoSmartComponents.filter(
          (NoSmartCompo) => NoSmartCompo.smartComponentType && NoSmartCompo.smartComponentType.id === smartComponentType.id
        );
        optionsSmartComponentTypes[smartComponentType.id] = noSmartsRelated;
      });
      // TODO change .at(-1)
      const option = {
        label: optionsSmartComponentTypes[this.state.newHardwareFromNewPackage.smartCompoTypeId].at(-1).name,
        value: optionsSmartComponentTypes[this.state.newHardwareFromNewPackage.smartCompoTypeId].at(-1).id,
      };
      this.handle2Selects(option, 'selectedSmartCompoTypesBySystemId', this.state.newHardwareFromNewPackage.smartCompoTypeId);
    }
    //** */

    this.setState({
      showAddHWModal: status,
      newHardwareFromNewPackage,
      allNoSmartComponents,
      optionsSmartComponentTypes,
    });
  };

  handleShowModalFromPackageWithNoSmartCompo = async (status: boolean, newHardwareSaved?: boolean) => {
    const newHardwareFromNewPackage: NewHardwareFromNewPackage = {
      smartCompoTypeId: null,
      systemId: this.state.systemId!,
      selectedType: 'nosmartComponentTypeId',
    };

    // use case after saved new hardware (noSmartCompo)
    if (!status && newHardwareSaved && this.state.newHardwareFromNewPackage) {
      const allNoSmartComponents = await this.getNoSmartComponents();
      const optionsNoSmartCompoBySystemId = allNoSmartComponents.filter(
        (NoSmartCompo) => NoSmartCompo.noSmartComponentType && NoSmartCompo.noSmartComponentType.systemId === this.state.systemId
      );
      // TODO change .at(-1)
      const option = {
        label: optionsNoSmartCompoBySystemId.at(-1).name,
        value: optionsNoSmartCompoBySystemId.at(-1).id,
      };
      this.handle2Selects(option, 'selectedNoSmartCompoBySystemId');
    }
    //** */

    this.setState({
      showAddHWModal: status,
      newHardwareFromNewPackage,
    });
  };

  getNoSmartComponents = async () => {
    const httpNoSmartCompo = new NoSmartComponentsHttpClient(this.context);
    const allNoSmartComponents = await httpNoSmartCompo.GetNoSmartComponents();
    return allNoSmartComponents.content;
  };

  render() {
    return (
      <>
        <ModalAddPackageView
          open={this.props.open}
          handleShowAddPackageModal={this.props.handleShowAddPackageModal}
          handleStep={this.handleStep}
          handleValueTextField={this.handleValueTextField}
          handleSystemId={this.handleSystemId}
          handleCheck={this.handleCheck}
          handle2Selects={this.handle2Selects}
          handleShowModalFromPackageWithSmartCompo={this.handleShowModalFromPackageWithSmartCompo}
          handleShowModalFromPackageWithNoSmartCompo={this.handleShowModalFromPackageWithNoSmartCompo}
          savePackage={this.savePackage}
          systemIds={this.props.systemIds}
          allSmartComponentTypes={this.props.allSmartComponentTypes}
          allHardwarePackage={this.props.allHardwarePackage}
          allNoSmartComponentsTypes={this.props.allNoSmartComponentsTypes}
          refreshAllNoSmartComponents={this.props.refreshAllNoSmartComponents}
          {...this.state}
        />
      </>
    );
  }
}

export default ModalAddPackageContainer;
