import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AuthenticationContext from "../../Authentication/types/AuthContextType";
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Error from '../Error';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import ParameterToolTipV1 from "./ParameterToolTipV1";

class CustomSlider extends Component {
    static contextType = AuthenticationContext;

    constructor(props) {
        super(props);
        this.state = { dataLoaded: false, customValue: "0" };
        this.marks = this.props.paramObj.values;
        this.valuetext = this.valuetext.bind(this);
        this.parameterValue = this.props.parameterValue;
    }

    componentDidMount() {
        this.value = null;
    }

    valuetext(value) { 
        const { t } = this.props;
        let finded = this.props.paramObj.values.find(elem => elem.value == value.toString());
        if (finded != undefined) {
            if(this.props.paramObj.table == "parameters"){
                finded = t(this.props.paramObj.table + "Labels:" + this.props.paramObj.id + "." + finded.value + ".name");
            }else{
                finded = t(this.props.paramObj.table + "Labels:" + this.props.paramObj.id + "." + finded.value);
            }
            return `${finded}`;
        }
        return `${0}`;
    }


    onMouseOverItem = e => {

        let menuPosition = document.getElementsByClassName("MuiAppBar-root")[0].getBoundingClientRect().top;
        let sliderPosition = document.getElementById(e.currentTarget.id).getBoundingClientRect().top

        let offset = sliderPosition - menuPosition;

        let element = document.getElementById("tooltip-" + e.currentTarget.id.replace('customSlider-', ''));

        if (offset < 325) {
            if (element != undefined) {
                element.classList.remove('box');
                element.classList.add('BottomTooltip');
            }
        }
        else {
            if (element != undefined) {
                element.classList.remove('BottomTooltip');
                element.classList.add('box');
            }
        }
    }

    render() {
        let dataLoaded = false;
        //let parameterValue = this.props.parameterValue;
        //if (this.value != null) {
        //    parameterValue = this.value;
        //}
        if (this.props.paramObj != null) {
            dataLoaded = true;
            //this.setState();
        }
        const { t } = this.props;
        const handleChange = (evnt, newValue) =>  {
            this.value = newValue;
            const finded = this.props.paramObj.values.find(elem => elem.value == newValue.toString());
            if (finded != undefined) {
                const { t } = this.props;
                
                let lbl = document.getElementById('label-' + this.props.paramObj.businessLabel);
                if(this.props.paramObj.table == "parameters"){
                    lbl.innerHTML = t(this.props.paramObj.table + "Labels:" + this.props.paramObj.id + "." + finded.value + ".name");
                }else{
                    lbl.innerHTML = t(this.props.paramObj.table + "Labels:" + this.props.paramObj.id + "." + finded.value);
                }
            }
        };
        
        const handleChangeCommited  = (evnt, newValue) => {
            this.value = newValue;
            const finded = this.props.paramObj.values.find(elem => elem.value == newValue.toString());
            if (finded != undefined) {
                let lbl = document.getElementById('label-' + this.props.paramObj.businessLabel);
                if(this.props.paramObj.table == "parameters"){
                    lbl.innerHTML = t(this.props.paramObj.table + "Labels:" + this.props.paramObj.id + "." + finded.value + ".name");
                }else{
                    lbl.innerHTML = t(this.props.paramObj.table + "Labels:" + this.props.paramObj.id + "." + finded.value);
                }
                this.props.toSend(this.props.paramObj, newValue);
            }

            //Call parent onchange
            if (typeof this.props.onChange === 'function') {
                this.props.onChange('slider');
            }
        };

        const handleDisabledClick = (disabled)=>{
            if(this.props.handleDisabledMessage && disabled){
                this.props.handleDisabledMessage(disabled);
            }
        }

        if (dataLoaded) {

            let disabled = this.props.paramObj.permissionType == 'R' || !this.props.paramObj.online;

            try {
                let style = {}
                if (this.props.propStyle)
                    style = this.props.propStyle
                //this.setState({ description: this.marks[this.props.parameterValue] });
                let max = Math.max.apply(Math, this.props.paramObj.values.map(function (o) { return o.value; }))
                let min = Math.min.apply(Math, this.props.paramObj.values.map(function (o) { return o.value; }))

                let minLabel = "0";
                let maxLabel = "0";
                let finded;
                let name = t(this.props.paramObj.table + ':' + this.props.paramObj.smartComponentTypeId + '.' + this.props.paramObj.businessLabel + '.name');
                let description = t(this.props.paramObj.table + ':' + this.props.paramObj.smartComponentTypeId + '.' + this.props.paramObj.businessLabel + '.description');
                if (this.props.parameterValue !== null) {
                    finded = this.props.paramObj.values.find(elem => elem.value == this.props.parameterValue.toString());

                    if (finded != undefined) {
                        minLabel = this.props.paramObj.values.find(elem => elem.value == min.toString());
                        let minTraduction = "";
                        if(this.props.paramObj.table == "parameters"){
                            this.state.customValue = t(this.props.paramObj.table+"Labels:"+this.props.paramObj.id+"."+finded.value + ".name");
                            minTraduction = t(this.props.paramObj.table + "Labels:" + this.props.paramObj.id + "." + minLabel.value + ".name")
                        }else{
                            this.state.customValue = t(this.props.paramObj.table+"Labels:"+this.props.paramObj.id+"."+finded.value);
                            minTraduction = t(this.props.paramObj.table + "Labels:" + this.props.paramObj.id + "." + minLabel.value)
                        }
                        minLabel = minLabel != undefined ? minTraduction : "0";
                        
                        maxLabel = this.props.paramObj.values.find(elem => elem.value == max.toString());
                        let maxTraduction = "";
                        if(this.props.paramObj.table == "parameters"){
                            maxTraduction = t(this.props.paramObj.table + "Labels:" + this.props.paramObj.id + "." + maxLabel.value + ".name")
                        }else{
                            maxTraduction = t(this.props.paramObj.table + "Labels:" + this.props.paramObj.id + "." + maxLabel.value)
                        }
                        maxLabel = maxLabel != undefined ? maxTraduction : "0";
                    }
                }
                return (
                    
                    <FormControl id={'customSlider-' + this.props.paramObj.businessLabel} className="customSlider" variant="standard" onMouseOver={this.onMouseOverItem} >
                        <ParameterToolTipV1 values={this.props.telemetryObject} description={description}>
                            <Typography className="t2" id={'discrete-slider-restrict-' + this.props.paramObj.businessLabel} gutterBottom style={{marginBottom: '2em', fontWeight: "bold !important"}}>
                                {name}
                            </Typography>
                        </ParameterToolTipV1>
                        {this.props.paramObj.infoText ?
                            <div className='info-tooltip-parameter customSlider'>                               
                                <Tooltip title={this.props.paramObj.infoText}>
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        :""}
                        
                        <FormLabel className="custom-slider-label t1 d-none" id={'label-' + this.props.paramObj.businessLabel}>{description}</FormLabel>
                        
                        <div onClick={() => handleDisabledClick({readOnly: this.props.paramObj.permissionType == 'R', online: this.props.paramObj.online})}>
                            <Slider
                                id={'s' + this.props.paramObj.businessLabel}
                                name={'s' + this.props.paramObj.businessLabel}
                                min={min}
                                max={max}
                                defaultValue={this.parameterValue}
                                aria-labelledby="discrete-slider-restrict"
                                onChange={handleChange}
                                onChangeCommitted={handleChangeCommited}
                                step={null}
                                marks={this.marks}
                                disabled={disabled}
                                valueLabelDisplay="on"
                                getAriaValueText={this.valuetext}
                                valueLabelFormat={this.valuetext}/>
                        </div>
                        
                        <div className="custom-slider-label-group mb-4" style={{ display: "flex", justifyContent: "space-between"}}>
                            <span className={'label-min t3'}>{minLabel}</span>
                            <span className={'label-max t3'}>{maxLabel}</span>
                        </div>
                    </FormControl>
                );
            } catch (e) {
                return (<Error errors={e} />);
            }

        }
        else {
            return (<div className="row loadingParent">< div className="loadingImg"></div></div >);
        }
    }
}
export default withTranslation()(CustomSlider)