import { t } from 'i18next';
import { ModalAddPackageStep3ViewProps } from '../types/ModalAddPackageTypes';
import { TextField } from '@mui/material';
import { FormGroup } from 'reactstrap';
import Form from 'react-bootstrap/Form';

const ModalAddPackageStep3View = (props: ModalAddPackageStep3ViewProps) => (
  <div className='ModalAddPackageStep3View'>
    <div className='prodChildNoZoom p-5'>
      <h2>{t('firmwareManagementPage.resume')}</h2>
      <div className='row'>
        <div className='col-12 col-md-6'>
          <h3>{t('firmwareManagementPage.packageName')}</h3>
          <TextField disabled size='small' fullWidth variant='outlined' defaultValue={props.name} />
        </div>
        <div className='col-12 col-md-3'>
          <h3>{t('firmwareManagementPage.systemId')}</h3>
          <TextField disabled size='small' fullWidth variant='outlined' defaultValue={props.systemId} />
        </div>
        <div className='col-12 col-md-3'>
          <h3>{t('firmwareManagementPage.version')}</h3>
          <TextField disabled size='small' fullWidth variant='outlined' defaultValue={props.version} />
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <h3>{t('forms.description')}</h3>
          <TextField disabled size='small' fullWidth variant='outlined' defaultValue={props.description} />
        </div>
      </div>

      <div className='row'>
        <FormGroup>
          <div>
            <div className='form-check pl-0' style={{ paddingTop: '35px' }}>
              <div className='flexContainer'>
                <div className='col-12 col-md-auto d-flex'>
                  <div>
                    <Form.Check className='p-0' type='checkbox' label='' checked={props.available} disabled />
                  </div>
                  <span className='t3 mb-0'> {t('firmwareManagementPage.available')} </span>
                </div>
                <div className='col-12 col-md-6 d-flex mt-4 mt-md-0'>
                  <div>
                    <Form.Check className='p-0' type='checkbox' label='' checked={props.production} disabled />
                  </div>
                  <span className='t3 mb-0'>{t('firmwareManagementPage.production')}</span>
                </div>
              </div>
            </div>
          </div>
        </FormGroup>
      </div>

      <h2 className='mt-5'>
        {t('firmwareManagementPage.components')}
        {/* SmartComponents */}
      </h2>
      <div className='row'>
        {props.selectedSmartCompoTypesBySystemId &&
          Object.keys(props.selectedSmartCompoTypesBySystemId).map((smartCompoId, key) => {
            const option = props.optionsSmartCompoTypesBySystemId.find((option) => option.id.toString() === smartCompoId)!;
            return (
              <div key={key} className='col-12 col-md-6'>
                <h3>{option.name}</h3>
                <TextField
                  disabled
                  size='small'
                  fullWidth
                  variant='outlined'
                  defaultValue={props.selectedSmartCompoTypesBySystemId![smartCompoId].label}
                />
              </div>
            );
          })}
        {props.selectedNoSmartCompoBySystemId && (
          <div className='col-12 col-md-6'>
            <h3>No Smart Component</h3>
            <TextField
              disabled
              size='small'
              fullWidth
              variant='outlined'
              defaultValue={props.selectedNoSmartCompoBySystemId.label}
            />
          </div>
        )}
      </div>
    </div>

    <div className='buttons row'>
      <div className='col'>
        <button
          onClick={() => props.handleStep('prev')}
          className={`MuiButtonBase-root MuiButton-root MuiButton-text primary pull-left float-left`}
          type='button'
        >
          <span className='MuiButton-label'>{t('actions.back')}</span>
          <span className='MuiTouchRipple-root'></span>
        </button>

        <button
          onClick={() => props.savePackage()}
          className={`MuiButtonBase-root MuiButton-root MuiButton-text primary pull-right float-right`}
        >
          <span className='MuiButton-label'>{t('actions.save')}</span>
          <span className='MuiTouchRipple-root'></span>
        </button>
      </div>
    </div>
  </div>
);

export default ModalAddPackageStep3View;
