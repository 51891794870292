import { PureComponent } from 'react';
import AuthenticationContext from '../../../../Authentication/types/AuthContextType';
import ReactECharts from 'echarts-for-react';
import { t } from 'i18next';
import { getColor, sumArrays } from '../../utils/chartComponentsUtils';
import _ from 'lodash';
import moment from 'moment';
import { Skeleton } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import EmptyBarChartView from '../../views/EmptyBarChartView';
class BarChartProductContainer extends PureComponent<any, any> {
  static contextType = AuthenticationContext;
  constructor(props: any) {
    super(props);
    this.state = {
      chartsData: false,
      dataPoints: false,
      chartOptions: false,
      initialChartOptions: false,
      error: false,
    };
  }

  componentDidMount = async () => {
    if (this.props.chart && !this.state.dataPoints && !this.state.chartsData) {
      await this.getDataPoints();
    }
  };

  setXaxisProduct = (dataPoints, daysDiff) => {
    const language = this.props.context.userPreferences.language;
    const chartDataArray: any[] = [];
    const xAxis: any[] = [];
    dataPoints.forEach(async (dataPoint) => {
      chartDataArray.unshift(dataPoint.value);
      switch (this.props.chart.groupByEcharts) {
        case 'day':
          xAxis.unshift(moment.utc(dataPoint.key).format('HH'));
          break;
        case 'year':
          xAxis.unshift(moment.utc(dataPoint.key).format('MMM'));
          break;
        case 'monthWeek':
          let beginWeek = moment.utc(dataPoint.key);
          let endWeek = moment.utc(dataPoint.key).add(6, 'days');
          xAxis.unshift(beginWeek.locale(language).format('DD MMM') + ' - ' + endWeek.locale(language).format('DD MMM'));
          break;
        default:
          if (daysDiff < 1) {
            xAxis.unshift(moment.utc(dataPoint.key).format('HH'));
          } else if (daysDiff < 5) {
            xAxis.unshift(moment.utc(dataPoint.key).locale(language).format('DD MMM (HH:00)'));
          } else {
            xAxis.unshift(moment.utc(dataPoint.key).locale(language).format('DD MMM'));
          }
          break;
      }
    });

    return { chartDataArray, xAxis };
  };

  setStackedKpisProduct = async (chartData) => {
    let newChartData = _.cloneDeep(chartData);
    newChartData.stacked = [];
    for (const kpiStacked of this.props.chart.kpisStacked) {
      let stacked: any = {};
      let responseKPIStacked = await this.props.chartRepository.productChartsKPIDatapoints(kpiStacked);
      stacked.name = kpiStacked.title;
      if (responseKPIStacked.status == 200) {
        let dataKPIStacked = responseKPIStacked.data;
        if (dataKPIStacked.content) {
          stacked.values = [];
          stacked.colors = getColor(kpiStacked.color);
          stacked.reference = null;
          for (const dataKpiStacked of dataKPIStacked.content) {
            if (dataKpiStacked) {
              const val = dataKpiStacked.value ? dataKpiStacked.value : 0;
              stacked.values.unshift(val);
            }
          }

          if (kpiStacked.kpiReference.kpiName) {
            let chartReference = _.cloneDeep(this.props.chart);
            chartReference.name = kpiStacked.kpiReference.kpiName;
            let responseKPISReference = await this.props.chartRepository.productChartsKPIDatapoints(chartReference);
            if (responseKPIStacked.status == 200) {
              let dataKPISReference = responseKPISReference.data;
              if (dataKPISReference.content) {
                let stackedReference: any = {};
                stackedReference.title = this.props.chart.kpiReference.title;
                stackedReference.values = [];
                for (const dataKpiReference of dataKPISReference.content) {
                  if (dataKpiReference) {
                    let val = dataKpiReference.value ? dataKpiReference.value : 0;
                    stackedReference['values'].unshift(val);
                  }
                }
                stacked.reference = stackedReference;
              }
            }
            newChartData.stacked.unshift(stacked);
          }
        }
      }
    }
    return newChartData;
  };

  setReferenceKpisProduct = async (chartData) => {
    let newChartData = _.cloneDeep(chartData);
    newChartData.reference = [];
    const referenceChart = {
      name: this.props.chart.kpiReference.kpiName,
      type: this.props.chart.type,
      groupBy: this.props.chart.groupBy,
      granularity: this.props.chart.granularity,
      calculation: this.props.chart.calculation,
      chartType: this.props.chart.chartType,
      dateRange: this.props.chart.dateRange,
    };

    let responseKPISReference = await this.props.chartRepository.productChartsKPIDatapoints(referenceChart);
    newChartData.nameReference = this.props.chart.kpiReference.title;
    if (responseKPISReference.status == 200) {
      let dataKPISReference = responseKPISReference.data;
      if (dataKPISReference.content) {
        for (let dataKpiReference of dataKPISReference.content) {
          if (dataKpiReference) {
            const val = dataKpiReference.value ? dataKpiReference.value : 0;
            newChartData.reference.unshift(val);
          }
        }
      }
    }
    return newChartData;
  };

  setBars = (chartData, reference) => {
    let newReference = _.cloneDeep(reference);
    const series: any[] = [];

    let bar = {
      name:
        this.props.legendTranslate == false
          ? chartData.name
          : chartData.nameStacked != undefined
          ? t('charts:titles.' + chartData.nameStacked)
          : t('charts:titles.' + chartData.name),
      type: 'bar',
      stack: 'Ad',
      itemStyle: {
        color: chartData.colors.color,
      },
      data: chartData.data,
    };
    series.push(bar);

    if (chartData.stacked) {
      for (let stacked of chartData.stacked) {
        let name = t('charts:titles.' + stacked.name);
        let bar = {
          name: name,
          type: 'bar',
          stack: 'Ad',
          itemStyle: {
            color: stacked.colors.color,
          },
          data: stacked.values,
        };
        series.push(bar);
        if (stacked.reference) {
          newReference.push(stacked.reference.values);
        }
      }
    }

    if (chartData.reference) {
      let nameRef = t('charts:titles.' + chartData.nameReference);
      let line = {
        name: nameRef,
        type: 'line',
        showSymbol: false,
        smooth: true,
        stack: 'average',
        lineStyle: {
          width: 0.5,
        },
        areaStyle: {
          opacity: 0.8,
        },
        itemStyle: {
          color: 'rgb(227, 228, 229)',
        },
        data: sumArrays(chartData.data.length, newReference),
      };
      series.push(line);
    }

    return series;
  };

  units;
  getDataPoints = async () => {
    try {
      const response = await this.props.chartRepository.manageChartType(this.props.chart);
      const dataPoints = response?.content;
      const deviceUnits = response?.deviceUnits;
      this.units = deviceUnits && deviceUnits != '' ? deviceUnits : this.props.chart.units

      if (!dataPoints) {
        this.setState({
          error: {
            type: 'call',
            message: t('translation:somethingWrong'),
          },
        });
        this.props.chartFinished(this.props.chart.kpiNameChartType);
        return null;
      } else if (!dataPoints.length) {
        this.setState({
          error: {
            type: 'empty',
            message: t('translation:nodata'),
          },
        });
        this.props.chartFinished(this.props.chart.kpiNameChartType);
        return null;
      }

      let chartOptions = _.cloneDeep(this.props.chart.options);
      const endRange = moment(this.props.chart.dateRange[1]);
      const beginRange = moment(this.props.chart.dateRange[0]);
      const daysDiff = endRange.diff(beginRange, 'days');

      let chartsData: any[] = [];
      const { xAxis, chartDataArray } = this.setXaxisProduct(dataPoints, daysDiff);
      let reference: any[] = [];

      let color;
      if (this.props.chart.color != undefined) {
        color = this.props.chart.color;
      } else {
        if (this.props.chart.title.includes('uses')) {
          color = '#A8DADC';
        } else if (this.props.chart.title.includes('volume')) {
          color = '#1d3557';
        } else {
          color = '#457B9D';
        }
      }

      let chartData: any = {
        name: this.props.chart.title,
        nameStacked: this.props.chart?.titleStacked,
        nameReference: this.props.chart.title,
        colors: getColor(color),
        data: chartDataArray,
        reference: [],
      };

      // Reference useCase
      if (this.props.chart.kpiReference) {
        const chartDataWithReference = await this.setReferenceKpisProduct(chartData);
        chartData = chartDataWithReference;
        reference.push(chartData.reference);
      }

      // KpisStacked useCase
      if (this.props.chart.kpisStacked) {
        const chartDataWithStacked = await this.setStackedKpisProduct(chartData);
        chartData = chartDataWithStacked;
      }

      // When is day fix time with :00
      if (this.props.chart.groupByEcharts === 'Day' || daysDiff < 1) {
        chartOptions.tooltip['axisPointer'] = {
          label: {
            formatter: '{value}:00',
          },
        };
      }

      chartOptions.xAxis.data = xAxis;
      chartOptions.xAxis.name = t('charts:axisTitles.' + this.props.chart.granularity.toLowerCase() + 's');
      chartOptions.yAxis.name = t('charts:axisTitles.' + this.units.toLowerCase());
      chartOptions.series = this.setBars(chartData, reference);
      delete chartOptions.title;
      chartsData.push(chartData);

      this.setState({
        dataPoints,
        chartsData,
        chartOptions,
        initialChartOptions: chartOptions,
      });
      this.props.chartFinished(this.props.chart.kpiNameChartType);
    } catch (error: any) {
      this.setState({
        error: {
          type: 'call',
          message: error?.message ? error?.message : error,
        },
      });
      this.props.chartFinished(this.props.chart.kpiNameChartType);
    }
  };

  renderTooltip = () => {
    if (
      this.props.chart.chartType.toLowerCase() === 'bar_echarts' &&
      this.props.chart.title.toLowerCase() === 'product uses' &&
      this.props.chartRepository.kpisChartContainer.props.page === 'product'
    ) {
      return (
        <div
          className='info-tooltip-parameter customTextField'
          style={{ position: 'static', marginBottom: '5px', paddingTop: '15px', marginLeft: '5px' }}
        >
          <Tooltip
            title={
              <>
                {t('tooltips.averageBarChartDay')}
                <br />
                <br />
                {t('tooltips.averageBarChartWeekMonth')}
              </>
            }
          >
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </div>
      );
    }
  };

  render() {
    const { error, chartsData, chartOptions, dataPoints } = this.state;
    const { title, siteReportingPage } = this.props.chart;

    return (
      <div className={`chart-group`}>
        <div className={`title header-chart-group ${error ? 'empty' : ''}`}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {!siteReportingPage && <h4>{t('charts:titles.' + title)}</h4>}
            {this.renderTooltip()}
          </div>
        </div>
        <div className='bar-chart-box'>
          {error ? (
            <EmptyBarChartView {...error} />
          ) : chartsData.length && chartOptions ? (
            <ReactECharts style={{ height: '500px', width: '100%', padding: '10px' }} option={chartOptions} />
          ) : !dataPoints.length ? (
            // <Skeleton animation='wave' variant='rectangular' width={638} height={500} style={{ margin: '0 auto' }} />
            <div className='row loadingParent'>
              <div className='loadingImg'></div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default BarChartProductContainer;
